import useCategoryList from "features/Category/CategoryList/useCategoryList";
import { SelectChangeEvent } from "@mui/material";
import { GlobalVariables } from "config/constant";
import { useSnackBar } from "hooks/useSnackBar";
import { ChangeEvent, useState } from "react";
import { useGetAllProductQuery } from "redux/api/product/product";
import { useGetAllSubCategoriesQuery } from "redux/api/category/category";

export default function useProductList(all:boolean) {
  const { categoryData } = useCategoryList();
  const { displayErrorApiSnackBar } = useSnackBar();
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [category, setCategory] = useState<any>("");
  const [subCategory_id, setSubCategory_id] = useState<any>("");
  const [filter, setFilter] = useState('all');
  const [perPage, setPerPage] = useState<number>(
    GlobalVariables.DefaultPerPage
  );
  const [orderBy, setOrderBy] = useState<string>("created_at");
  const [orderDir, setOrderDir] = useState<"" | "asc" | "desc">("desc");
  const { data: subcategories, isLoading:isload } = useGetAllSubCategoriesQuery({
    id: category,
    all:true
  });
  const {
    data: productData,
    error,
    isLoading,
    refetch
  } = useGetAllProductQuery(all?{
    paginator: {
      search: search,
      page: page,
      perPage: perPage,
      orderBy: orderBy,
      orderDir: orderDir,
    },      all:true,category:category,Subcategory:subCategory_id,Stock:filter
}:
    {
      paginator: {
        search: search,
        page: page,
        perPage: perPage,
        orderBy: orderBy,
        orderDir: orderDir,
      },category:category,Subcategory:subCategory_id,Stock:filter
  });

  if (error) {
    displayErrorApiSnackBar(error);
    
  }
 
 
  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    setPage(1);
    if (typeof e === "string") {
      setSearch(e);
      
    } else {
      setSearch(e.target.value);
    }
  };
  const changeFilter = (e: any) => {
    setFilter(e);
    setPage(1);
   
    
  };
  const onChangeSelectCategory = (selectedValue: string) => {
    console.log(selectedValue);
    setCategory(selectedValue);
  };
  const onChangeSelectSubCategory = (selectedValue: string) => {
    console.log(selectedValue);
    setSubCategory_id(selectedValue);
  };
  const onPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(parseInt(String(event.target.value), 10));
    setPage(1);
  };

  const onOrderChange = (field: string) => () => {
    const order_dir =
      orderBy === field
        ? orderDir === "asc"
          ? "desc"
          : orderDir === "desc"
          ? ""
          : "asc"
        : "asc";
    setOrderDir(order_dir);
    setOrderBy(order_dir === "" ? "" : field);
  };

  return {
    onChangeSelectCategory,
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    productData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    categoryData,
    category,
    subcategories,
    subCategory_id,
    onChangeSelectSubCategory,
    changeFilter,
    filter
  };
}
