import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


// Function to create a new Point of Sale
export const createPointOfSale = async (data, token) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/point-of-sale/create`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,

                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error creating Point of Sale:', error);
        throw error;
    }
};
export const getPointOfSales = async (token, page, perPage) => {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/point-of-sale`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,

                },
                params: {
                    page,
                    perPage
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error Getting Point of Sale:', error);
        throw error;
    }
};

export const updatePointOfSale = async (id, data, token) => {
    try {
        const response = await axios.put(
            `${API_BASE_URL}/point-of-sale/${id}`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating Point of Sale:', error);
        throw error;
    }
};
export const getSellers = async (token, page, perPage) => {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/sellers`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    page,
                    perPage
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error Getting Sellers List:', error);
        throw error;
    }
};
export const createSeller = async (data, token) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/sellers/create`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,

                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error creating Seller:', error);
        throw error;
    }
};

export const updateSeller = async (seller, data, token) => {
    try {
        const response = await axios.put(
            `${API_BASE_URL}/sellers/update/${seller}`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating Seller:', error);
        throw error;
    }
};

export const enableDisableClient = async (user, token) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/clients/enable-disable/${user}`,
            {}, // Empty body if not required
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error disabling or enabling Seller:', error);
        throw error;
    }
};
