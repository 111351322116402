import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./EditPromotionCode.style";
import dayjs, { Dayjs } from 'dayjs';

import { useUpdatePromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import {
  IPromotionCodesDialog,
  IPromotionCodesEditingData,
  IPromotionCodesEditingDataError,
} from "./EditPromotionCode.types";
import { useSnackBar } from "hooks/useSnackBar";
import SelectInput from "components/common/SelectInput/SelectInput";
import SelectInputShow from "components/common/SelectInput/SelectInputShow";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from "moment";
import TextInput from "components/common/TextInput/TextInput";
import { CheckBox } from "@mui/icons-material";
import { Checkbox, Chip, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { AutoInputsContainerStyle } from "features/Product/ProductAdd/Product.style";
import useCategoryList from "features/Category/CategoryList/useCategoryList";
import useSubCategoryList from "features/SubCategory/SubCategoryList/useSubCategoryList";
import useProductList from "features/Product/ProductList/useProductList";
import useClientList from "features/Client/ClientList/useClientList";
import AutoComplete from "components/common/AutoComplete/AutoComplete";
import { number } from "prop-types";
function EditPromotionCode({
  id,
  codeInfos,
  openEditingDialog,
  setOpenEditingDialog,
}: IPromotionCodesDialog) {
  const { t } = useTranslation();
  console.log("codeInfosPromo", codeInfos)
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [editingCode, { data, error, isLoading, reset }] =
    useUpdatePromotionCodeMutation();
  const closeEditingDialog = useCallback(() => {
    setOpenEditingDialog(false);
    setEditingData({
      code: "",
      percentage: 0,
      email: "",
      link: "",
      categories: [],
      subCategories: [],
      type: "",
      isLimitedusers: "",
      isLinkedUsers: false,
      isUserExist: "",
      client: "",
      percentage_value: "",
      eur_value: "",
      isUserNotExist: false,
      linkcodesubscription: false,
      subscriptions: "",
      isLinkedPromoSubscription: false,
      isUnlimitedUsers: false,
      isDiscountMin: false,
      discount: "",
      nbUser: "",
      products: [],
      start_date: "",
      end_date: ""
    });
  }, [setOpenEditingDialog]);
  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    closeEditingDialog()
    displaySuccessSnackBar(t("promotion_codes.successfully_editing"));
    reset();
  }

  console.log("codeInfos", codeInfos)
  const [editingData, setEditingData] = useState<IPromotionCodesEditingData>({
    code: codeInfos.code,
    percentage: codeInfos.percentage,
    start_date:  codeInfos.start_date,
    end_date:  codeInfos.end_date,
    link: codeInfos.link === "categories" ? "1" : codeInfos.link === "subcategories" ? "2" : codeInfos.link === "products" ? "3" : codeInfos.link,
    categories: eval(codeInfos.categories),
    subCategories: eval(codeInfos.subCategories),
    type: codeInfos.type,
    isLimitedusers: codeInfos.isLimitedusers,
    isLinkedUsers: codeInfos.isLinkedUsers,
    isUserExist: codeInfos.isUserExist,
    isUserNotExist: codeInfos.isUserNotExist,
    linkcodesubscription: codeInfos.linkcodesubscription,
    subscriptions: eval(codeInfos.subscriptions),
    isLinkedPromoSubscription: codeInfos.isLinkedPromoSubscription,
    isDiscountMin: codeInfos.isDiscountMin,
    isUnlimitedUsers: codeInfos.isUnlimitedUsers,
    nbUser: codeInfos.nbUser,
    discount: codeInfos.discount,
    email: codeInfos.email,
    products: eval(codeInfos.products),
    percentage_value: codeInfos.percentage_value,
    eur_value: codeInfos.eur_value,
    client: codeInfos.client,
  });
  useEffect(()=>{
    
    console.log("editingDataachraf", editingData)
   },[editingData])
  const { categoryData } = useCategoryList()
  const { subCategoryData } = useSubCategoryList()
  const { productData } = useProductList(true)
  const { clientData } = useClientList()
  const [editingError, setEditingError] =
    useState<IPromotionCodesEditingDataError>({
      code: "",
      percentage: 0,
      email: "",
      link: "",
      categories: "",
      subCategories: "",
      type: "",
      isLimitedusers: "unlimited",
      isLinkedUsers: false,
      isUserExist: "",
      client: "",
      percentage_value: "",
      eur_value: "",
      isUserNotExist: false,
      linkcodesubscription: false,
      subscriptions: "",
      isLinkedPromoSubscription: false,
      isUnlimitedUsers: false,
      isDiscountMin: false,
      discount: "",
      nbUser: "",
      products: "",
      start_date: "",
      end_date: ""
    });

    const validate = () => {
      let ok = true
      let editingPromotionError: IPromotionCodesEditingDataError = {
        code: "",
        percentage: 0,
        email: "",
        client: "",
        link: "",
        categories: "",
        subCategories: "",
        type: "",
        isLimitedusers: "",
        isLinkedUsers: false,
        isLinkedPromoSubscription: false,
        nbUser: "",
        isUserExist: "",
        percentage_value: "",
        eur_value: "",
        isUserNotExist: false,
        linkcodesubscription: false,
        isUnlimitedUsers: false,
        subscriptions: "",
        isDiscountMin: false,
        discount: "",
        products: "",
        start_date: "",
        end_date: ""
      }
      if (editingData.code == "") {
        ok = false
        editingPromotionError.code = "Code obligatoire"
      }
      else {
        editingPromotionError.code = ""

      }
      if(editingData.link==""){
        ok=false
        editingPromotionError.link="Liaison obligatoire"
      }
      else {
        editingPromotionError.link=""
      }
      if(editingData.link=="3"&& editingData.products.length==0){
        ok=false
        editingPromotionError.products="Veullez selectionnez les produits"
      }
      else {
        editingPromotionError.products=""

      }
      if(editingData.link=="1"&& editingData.categories?.length==0){
        ok=false
        editingPromotionError.categories="Veullez selectionnez les catégories"
      }
      else {
        editingPromotionError.categories=""

      }
      if(editingData.link=="2"&& editingData.subCategories?.length==0){
        ok=false
        editingPromotionError.subCategories="Veullez selectionnez les sous catégories"
      }
      else {
        editingPromotionError.subCategories=""

      }
      if(editingData.type==""){
        ok=false
        editingPromotionError.type="Type obligatoire"
      }
      else {
        editingPromotionError.type=""
      }


      if (Number(editingData.percentage_value) < 0 || Number(editingData.percentage_value) > 100) {
        ok = false
        editingPromotionError.percentage_value = "Pourcentage entre 0 et 100"
      }
      else
        editingPromotionError.percentage_value = ""


      console.log("addingPromotionError", editingPromotionError)
      setEditingError(editingPromotionError)
      return ok
    }



  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault()
    console.log("ok",validate())
    let link
    editingData.link=="1"?link="categories":editingData.link=="2"?link="subcategories":link="products"
    let type
    editingData.type=="1"?type="product_percent":editingData.type=="3"?type="product_value":editingData.type=="2"?type="free_shipping":type=""
    const data={...editingData}
    data.link=link;
    data.type=type
    if (validate()) {
      
      editingCode({ id: id, ...data,categories:data.categories.map((categ)=>categ.id),
        subCategories:data.subCategories.map((categ)=>categ.id),
        products:data.products.map((categ)=>categ.id),
        start_date:Math.floor(data.start_date.getTime() / 1000), end_date:Math.floor(data.end_date.getTime() / 1000)});
      closeEditingDialog()
    };
  }

 
  useEffect(()=>{
    codeInfos.client ?setEditingData({...editingData,isUserExist:"exists"}):
    setEditingData({...editingData,isUserExist:"not exists"})
    codeInfos.nbUser ?setEditingData({...editingData,isLimitedusers:"limité"}):
    setEditingData({...editingData,isLimitedusers:"unlimited"})

   },[codeInfos.client,codeInfos.nbUser])
  useEffect(() => {
    if (openEditingDialog && codeInfos)
      console.log("test1109",codeInfos)
      setEditingData({
        code: codeInfos.code,
        percentage: codeInfos.percentage,
        start_date:new Date(codeInfos.start_date*1000),
        end_date: new Date(codeInfos.end_date*1000),
        link: codeInfos.link === "categories" ? "1" : codeInfos.link === "subcategories" ? "2" : codeInfos.link === "products" ? "3" : codeInfos.link,
        categories: codeInfos.categories && codeInfos.categories !="null"
        ? eval(codeInfos?.categories).map((categ:number) => ({
          id: (categoryData?.data.filter((category:any) => categ == category?.id)[0])?.id,
          name: (categoryData?.data.filter((category:any) => category?.id == categ)[0])?.title
      })): [],
        subCategories: codeInfos.subCategories && codeInfos.subCategories !="null"
        ? eval(codeInfos.subCategories).map((subcat:number) => ({
          id: categoryData?.data.filter((category:any) => subcat == category.id)[0]?.id,
          name: subCategoryData?.data.filter((category:any) => category.id == subcat)[0]?.title
      })): [],
        type: codeInfos.type,
        isLimitedusers: codeInfos.isLimitedusers,
        isLinkedUsers: codeInfos.isLinkedUsers,
        isUserExist: codeInfos.isUserExist,
        isUserNotExist: codeInfos.isUserNotExist,
        linkcodesubscription: codeInfos.linkcodesubscription,
        subscriptions: eval(codeInfos.subscriptions),
        isLinkedPromoSubscription: codeInfos.isLinkedPromoSubscription,
        isDiscountMin: codeInfos.isDiscountMin,
        isUnlimitedUsers: codeInfos.isUnlimitedUsers,
        nbUser: codeInfos.nbUser,
        discount: codeInfos.discount,
        email: codeInfos.email,
        products: codeInfos.products && codeInfos.products !="null"
        ? eval(codeInfos.products).map((prod:number) => ({
          id: productData?.data.filter((product:any) => prod == product.id)[0]?.id,
          name: productData?.data.filter((product:any) => product.id == prod)[0]?.title
      })): [],
        percentage_value: codeInfos.percentage_value,
        eur_value: codeInfos.eur_value,
        client: codeInfos.client,
      });
  }, [
    openEditingDialog,
    codeInfos.code,
    codeInfos.percentage,
    codeInfos.start_date,
    codeInfos.end_date,
    codeInfos.link,
    codeInfos.categories,
    codeInfos.subCategories,
    codeInfos.type,
    codeInfos.isLinkedUsers,
    codeInfos.isLinkedUsers,
    codeInfos.isUserExist,
    codeInfos.isUserNotExist,
    codeInfos.linkcodesubscription,
    codeInfos.subscriptions,
    codeInfos.isLinkedPromoSubscription,
    codeInfos.isDiscountMin,
    codeInfos.isUnlimitedUsers,
    codeInfos.nbUser,
    codeInfos.discount,
    codeInfos.email,
    codeInfos.products,
    codeInfos.percentage,
    codeInfos.percentage_value,
    codeInfos.eur_value,
    codeInfos.client,
    codeInfos.type,
    id,
  ])

  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditingData({ ...editingData, [field]: e.target.value });
        validate()
        if (field === "start_date" || field === "end_date") {
          console.log("e date", field)
          setEditingData({
            ...editingData,
            [field]: moment(e.target.value).format("YYYY/MM/DD"),
          });
        }
        if (field == "percentage_value") {
          if (Number(e.target.value) > 0 && Number(e.target.value) <= 100) {
            setEditingData({ ...editingData, percentage_value: e.target.value });

          }
          else {
            setEditingData({ ...editingData, percentage_value: "" });
          }
        }
      },
    [editingData]
  );
  const onChangeSelectLink = (e: string) => {
    console.log("e link",e)
    setEditingData({ ...editingData, link: e});
    console.log("editingData", editingData)
    setEditingError({ ...editingError, link: "" });

  }
  useEffect(()=>{
    console.log("editingData",editingData)
  },[editingData])
  const onChangeSelectCategory = (e: any) => {


    setEditingData({ ...editingData, categories: e });
    setEditingError({ ...editingError, categories: "" });

  }
  const handleInputChange = (event: any, newValue: any,type:string) => {
    switch(type){
      case "products":{
    setEditingData({
      ...editingData,
      products: [...newValue], // Adding new element to the array
    });
    setEditingError((prevState) => ({
      ...prevState,
      product: "" // Adding new element to the array
    }));

    break;
  }
    case "categories":{
      setEditingData({
        ...editingData,
        categories: [...newValue], // Adding new element to the array
      });
      setEditingError((prevState) => ({
        ...prevState,
        categories: "" // Adding new element to the array
      }));
    
      break;
    }
      case "subcats":{
        setEditingData({
          ...editingData,
          subCategories: [...newValue], // Adding new element to the array
        });
        setEditingError((prevState) => ({
          ...prevState,
          subCategories: "" // Adding new element to the array
        }));

        break;
      }
  }
  }


  const onChangePercentageValue = (e: any) => {
    setEditingData({ ...editingData, percentage_value: e.target.value });
  }

  const onChangeEurValue = (e: any) => {
    setEditingData({ ...editingData, eur_value: e.target.value });
  }

  const onChangeSelectType = useCallback((field: string) => (e: string) => {

    setEditingData({ ...editingData, type: e.toString() });
    setEditingError({ ...editingError, type: "" });
  },
    [editingData, editingError]
  );
  const onChangeSelectSubcscription = useCallback((field: string) => (e: string) => {

    setEditingData({ ...editingData, subscriptions: e });
    setEditingError({ ...editingError, subscriptions: "" });

  },
    [editingData, editingError]
  );
  const onChangeSelectClient = useCallback((field: string) => (e: string) => {

    setEditingData({ ...editingData, client: e });
    setEditingError({ ...editingError, client: "" });

  },
    [editingData, editingError]
  );


  return (
    <Dialog
      open={openEditingDialog}
      handleClose={closeEditingDialog}
      title={t("promotion_codes.edit")}
    >
      <form onSubmit={onSuccessEditingDialog}>

        <AutoInputsContainerStyle item xs={12} md={12} lg={12}>

            <TextInput
              value={editingData.code}
              label={t("promotion_codes.code")}
              onChange={onChange("code")}
              error={t(editingError.code)}
            />
        </AutoInputsContainerStyle>
        <AutoInputsContainerStyle item xs={12} md={12} lg={12}>

          
          <SelectInput
            label={"liaison"}
            error={editingError.link}
            value={editingData.link}
            onChange={onChangeSelectLink}

            data={[{ id: 1, name: "Categories" }, { id: 2, name: "Sous Catégories" }, { id: 3, name: "Produit" }]}
          />
          
          
          
          
          </AutoInputsContainerStyle>
          {

            editingData.link == "1" &&
            <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
     {categoryData&&<AutoComplete
                label=""
                multiple={true}
                data={categoryData?.data?.map((e: any) => ({
                  id: e.id,
                  name: e.title,
                }))}
                getOptionLabel={(option: any) => { return option ? option.name : "Catgéorie" }}
                value={editingData.categories}
                onChange={(event: any, newValue: any) => {
                  console.log("newValue",newValue)
             
                 handleInputChange(event, newValue,"categories")

                }}
                renderTags={(value:any, getTagProps: any) => {
                  console.log("value",value)
                  return editingData.categories.map((option: any, index: number) => {
                    console.log("option", option)
                    return <Chip
                      {...getTagProps({ index })}
                      label={option.name}
                    />
                  })
                }}
                noOptionsText={
                    <a style={{cursor:"pointer",textDecoration:"underline"}}>Aucun produit trouvé</a>

                }
                renderInput={(params) => (
                  <TextField {...params} error={editingError.subCategories !== ""} helperText={editingError.subCategories} label="Catégories" />
                )}
              />
              }
      
            
            </AutoInputsContainerStyle>
          }
          {
            editingData.link == "2" &&
            <AutoInputsContainerStyle item xs={12} md={12} lg={12}>

{subCategoryData&&<AutoComplete
                label=""
                multiple={true}
                data={subCategoryData?.data?.map((e: any) => ({
                  id: e.id,
                  name: e.title,
                }))}
                getOptionLabel={(option: any) => { return option ? option.name : "Produit" }}
                value={editingData.subCategories ?editingData.subCategories: [{ id: 0, name: "Tous"}]}
                onChange={(event: any, newValue: any) => {
                  console.log("newValue",newValue)
             
                  handleInputChange(event, newValue,"subcats")

                }}
                renderTags={(value:any, getTagProps: any) => {
                  console.log("value",value)
                  return value.map((option: any, index: number) => {
                    console.log("option", option)
                    return <Chip
                      {...getTagProps({ index })}
                      label={option.name}
                    />
                  })
                }}
                noOptionsText={
                    <a style={{cursor:"pointer",textDecoration:"underline"}}>Aucun produit trouvé</a>

                }
                renderInput={(params) => (
                  <TextField {...params} error={editingError.subCategories !== ""} helperText={editingError.subCategories} label="Sous catégories" />
                )}
              />
              }</AutoInputsContainerStyle>
          }
          {
            editingData.link == "3" &&
            <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
              {productData&&<AutoComplete
                label=""
                multiple={true}
                data={productData?.data?.map((e: any) => ({
                  id: e.id,
                  name: e.title,
                }))}
                getOptionLabel={(option: any) => { return option ? option.name : "Produit" }}
                value={editingData.products ?editingData.products: [{ id: 0, name: "Tous"}]}
                onChange={(event: any, newValue: any) => {
                  console.log("newValue",newValue)
             
                  handleInputChange(event, newValue,"products")

                }}
                renderTags={(value:any, getTagProps: any) => {
                  console.log("value",value)
                  return value.map((option: any, index: number) => {
                    console.log("option", option)
                    return <Chip
                      {...getTagProps({ index })}
                      label={option.name}
                    />
                  })
                }}
                noOptionsText={
                    <a style={{cursor:"pointer",textDecoration:"underline"}}>Aucun produit trouvé</a>

                }
                renderInput={(params) => (
                  <TextField {...params} error={editingError.products !== ""} helperText={editingError.products} label="Produit" />
                )}
              />
              }
            </AutoInputsContainerStyle>
          }

        <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
          <SelectInput
            label={"Type code promotion"}
            error={editingError.type}
            value={editingData.type=="product_percent"?1:editingData.type=="product_value"?3:editingData.type=="free_shipping"?2:editingData.type}
            onChange={onChangeSelectType("type")}

            data={[{ id: 1, name: "Remise en % sur Produit" }, { id: 2, name: "Livraison gratuite" }, { id: 3, name: "Remise en euro sur Produit" },
            { id: 4, name: "Rabais en % sur livraison" }, { id: 5, name: "Rabais en euro sur livraison" },
            { id: 6, name: "retrait drive en %" }, { id: 7, name: "retrait drive en euro", }

            ]}
          /></AutoInputsContainerStyle>

          {(editingData.type === "1" || editingData.type == "4" || editingData.type == "6") &&
            ( <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
              value={editingData.percentage_value}
              label="Pourcentage"
              type="number"
              onChange={onChangePercentageValue}
              error={t(editingError.percentage_value)}
            /></AutoInputsContainerStyle>)
          }

          {(editingData.type == "3" || editingData.type == "7" || editingData.type == "5") &&
            ( <AutoInputsContainerStyle item xs={12} md={12} lg={6}><TextInput
              value={editingData.eur_value}
              label='Euro'
              type="number"
              onChange={onChangeEurValue}
              error={t(editingError.eur_value)}
            /></AutoInputsContainerStyle>)


          }


        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={editingData.isLimitedusers}
          onChange={(e) => { setEditingData({ ...editingData, isLimitedusers: e.target.value }) }}
          style={{ display: "100%" }}
        >
          <div style={{ display: "inline-flex", width: "100%" }}>
            <FormControlLabel value={"limited"} control={<Radio />} label="limité" />
            <FormControlLabel value={"unlimited"} control={<Radio />}
              label="illimité" />
          </div>
        </RadioGroup>
        {editingData.isLimitedusers == "limited" && <TextInput
          value={editingData.nbUser}
          type="number"
          label={t("promotion_codes.nbUser")}
          onChange={onChange("nbUser")}
          error={t(editingError.nbUser)}
        />
        }
        <FormControlLabel control={<Checkbox checked={editingData.isLinkedUsers} value={editingData.isLinkedUsers} onChange={(e) => { setEditingData({ ...editingData, isLinkedUsers: e.target.checked }) }} />} label="lier à des utilisateurs spécifiques" /><br />
        {editingData.isLinkedUsers==true && <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={editingData.isUserExist}
          onChange={(e) => { setEditingData({ ...editingData, isUserExist: e.target.value }) }}
          style={{ display: "100%" }}
        >
          <div style={{ display: "inline-flex", width: "100%" }}>
            <FormControlLabel value={"exists"} control={<Radio />} label="Utilistateur existant" />
            <FormControlLabel value={"not exists"} control={<Radio />}
              label="Utilisateur not existant" />
          </div>
        </RadioGroup>
        }
        {
          editingData.isLinkedUsers==true && editingData.isUserExist == "exists" && <SelectInput
            label={t("promotion_codes.client")}
            error={editingError.client}
            value={editingData.client}
            onChange={onChangeSelectClient("client")}

            data={clientData.data.map((client: any) => ({ id: client.id, name: client.email }))}

          />}
        {
          editingData.isLinkedUsers==true && editingData.isUserExist == "not exists" && <TextInput
            value={editingData.email}
            label="email"
            onChange={onChange("email")}
            error={t(editingError.email)}
          />
        }

     {/*    <FormControlLabel control={<Checkbox checked={editingData.isLinkedPromoSubscription} value={editingData.isLinkedPromoSubscription} onChange={(e) => { setEditingData({ ...editingData, isLinkedPromoSubscription: e.target.checked }) }} />} label="lier code promotion à un abonnement" />
        {editingData.isLinkedPromoSubscription == true && <SelectInput
          label={t("promotion_codes.subscriptions")}
          error={editingError.subscriptions}
          value={editingData.subscriptions}
          onChange={onChangeSelectSubcscription("subscriptions")}

          data={[{ id: 1, name: "Remise en % sur Produit" }, { id: 2, name: "Livraison gratuite" }, { id: 3, name: "Remise en eur sur Produit" },
          { id: 4, name: "Rabais en % sur livraison" }, { id: 4, name: "Rabais en eur sur livraison" },
          { id: 5, name: "retrait drive en %" }, { id: 6, name: "retrait drive en eur", }

          ]}
        />

        }
        <br /> */}
        <FormControlLabel control={<Checkbox checked={editingData.isDiscountMin} value={editingData.isDiscountMin} onChange={(e) => { setEditingData({ ...editingData, isDiscountMin: e.target.checked }) }} />} label="Montant Minimum" />
        <InputStyle>
          {editingData.isDiscountMin==true && <TextInput
            type="number"
            value={editingData.discount}
            label={t("promotion_codes.discount")}
            onChange={onChange("discount")}
            error={t(editingError.discount)}
          />
          }
        </InputStyle>
        <AutoInputsContainerStyle item xs={12} md={12} lg={6}>

 
          <InputStyle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

              <DateTimePicker
                label={t("promotion_codes.start_date_title")}
                value={
                 dayjs(editingData.start_date)

                }
                minDateTime={dayjs(new Date(new Date().setDate(new Date().getDate() - 1)))}
                //defaultValue={ dayjs(new Date())}

                onChange={(start: any) => { console.log("start",start);setEditingData({ ...editingData, start_date: new Date(start) });validate() }}
              />

            </LocalizationProvider>

          </InputStyle>


          <InputStyle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

              <DateTimePicker
                label={t("promotion_codes.end_date_title")}
                value={
                  dayjs(editingData.end_date)
                }
                minDateTime={dayjs(new Date(editingData.start_date))}
                
                onChange={(end: any) => {
                  console.log("end", end);
                  setEditingData({ ...editingData, end_date: new Date(end) });
                  validate()
                }}
              />


            </LocalizationProvider>
          </InputStyle>


        </AutoInputsContainerStyle>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeEditingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default EditPromotionCode;
