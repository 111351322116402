import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import { Stack } from "@mui/material";
import {
    IClientData,
    IClientsDialog,
} from "./ShowClient.types";
import { useUpdateOrderStatusMutation } from "redux/api/order/order";
import {  useGetClientByIdQuery } from "redux/api/client/client";
function ShowClient({
  id,
  openShowDialog,
  setOpenShowDialog,
}: IClientsDialog) {
  const { t } = useTranslation();
  const {
    data: clientDataInfo,
    isSuccess,
    refetch: refetchProductItem,
  } = useGetClientByIdQuery(Number(id));
  

  const [clientData, setClientData] = useState<IClientData|undefined>()

 useEffect(()=>{
  setClientData(clientDataInfo)
 },[clientDataInfo])

useEffect(()=>{
  console.log("clientData",clientData);
  
},[clientData])



 
  const closeEditingDialog = useCallback(() => {
    setOpenShowDialog(false);
    setClientData({ 
      id:undefined,
      birth:"",
    city:"",
    country:"",
    delivery_address:"",
    email:"",
    first_name:"",
    foyer_composition:"",
    gender:"",
    housing_status:"",
    housing_type:"",
    is_enable:0,
    job:"",
    last_name:"",
    payment_expiration:"",
    phone:"",
    photo:"",
    role:0,
    status:0,
    zip_code:""
    });
  }, [setOpenShowDialog]);
  return (
    <Dialog
      open={openShowDialog}
      handleClose={closeEditingDialog}
      title={t("client.show")}
    >   
        <img style={{position:"absolute",top:"0",left:"42%",textAlign:"center"}} src={clientData?.photo} width={100} height={100}/>
        <div style={{ textAlign:"center"}}><h3 >{clientData?.last_name}_{clientData?.first_name}</h3></div>
     
    <Stack spacing={15}   direction={{ xs: 'column', sm: 'row' }}
>
    <Stack spacing={1} direction="column">

        <div style={{display:"inline-flex",justifyContent:"center", alignItems:"center"}}>
        <h5 style={{color:"#DA70D6",textAlign:"center"}}>
       Email:
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.email}
        </p>
        </div>
     

      
      
        <div style={{display:"inline-flex",alignItems:"center"}}>

        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
       Date de naissance:
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.birth}
        </p>
        </div>
        <div style={{display:"inline-flex",alignItems:"center"}}>
        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
      Genre:
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.gender}
        </p>
        </div>
        <div style={{display:"inline-flex",alignItems:"center"}}>

        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
      Pays:
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.country}
        </p>
        </div>
        <div style={{display:"inline-flex",alignItems:"center"}}>
        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
      Code postal:
       </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.zip_code}
        </p>
        </div>
        <div style={{display:"inline-flex",alignItems:"center"}}>
        <h5 style={{color:"#DA70D6	"}}>
      Maison:
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.housing_type}
        </p>
        </div>
        </Stack>
        <Stack spacing={1} direction="column">
    
<div style={{display:"inline-flex",alignItems:"center"}}>

        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
       Téléphone:
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.phone}
        </p>
</div>
<div style={{display:"inline-flex",alignItems:"center"}}>

        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
       Etat travail:
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.job}
        </p>
</div>
<div style={{display:"inline-flex",alignItems:"center"}}>

        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
       Ville :
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.city}
        </p>
        </div>
        <div style={{display:"inline-flex",alignItems:"center"}}>

        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
        Membres famille :
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.foyer_composition}
        </p>
        </div>
        <div style={{display:"inline-flex",alignItems:"center"}}>
        <h5 style={{color:"#DA70D6	",textAlign:"center"}}>
        Proprietaire? :
      </h5>
        <p style={{textAlign:"center"}}>
          {clientData?.housing_status}
        </p>
        </div>

       
     </Stack>
     </Stack>


    </Dialog>
  );
}
export default ShowClient;
