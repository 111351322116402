import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
} from "./DeleteExpeditionMode.style";
import { useDeletePromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import { IExpeditionModeDelete } from "./DeleteExpeditionMode.types";
import { useSnackBar } from "hooks/useSnackBar";
import { useDeleteExpeditionModeMutation } from "redux/api/delivery/expeditionMode/expeditionMode";
import { useNavigate } from "react-router-dom";

function DeleteExpeditionMode({
  id,
  mode,
  openDeleteDialog,
  setOpenDeleteDialog,
}: IExpeditionModeDelete) {
  const { t } = useTranslation();
  const navigate=useNavigate()

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [deleteCode, { data, error, isLoading }] =
    useDeleteExpeditionModeMutation();

  if (error) {
    displayErrorApiSnackBar(error);
  }
  if (data) {
    displaySuccessSnackBar(t("delivery.successfully_deleted_mod"));
  }

  const closeDeleteAlertDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, [setOpenDeleteDialog]);

  const onSuccessDeleteAlert = (event: React.FormEvent) => {
    event.preventDefault();
    deleteCode(id);
    closeDeleteAlertDialog();
  };

  return (
    <Dialog
      open={openDeleteDialog}
      handleClose={closeDeleteAlertDialog}
      title={t("promotion_codes.delete")}
    >
      <form onSubmit={onSuccessDeleteAlert}>
        <p>
          {t("common.delete_message")}
          <span style={{ fontWeight: "bold", marginLeft: 5 }}>{mode}</span>!
        </p>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeDeleteAlertDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default DeleteExpeditionMode;
