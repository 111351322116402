import {
  ConatinerStyle,
  DeviderStyle,
  ErrorStyle,
  FragmentStyle,
  InputConatinerStyle,
  InputDateStyle,
  InputMultipleStyle,
  InputNumberStyle,
  InputStyle,
  LabelStyle,
  PasswordIconStyle,
  PasswordStyle,
  TitleStyle,
} from "./TextInput.style";
import { useTranslation } from "react-i18next";
import { TextInputPropsInterface } from "./TextInput.type";
import { Fragment, useCallback, useState } from "react";
import eye from "../../../assets/img/eye.png";
import hiddenEye from "../../../assets/img/hidden_eye.png";
import { TextField,FormControl,TextareaAutosize } from "@mui/material";

function Button({
  required,
  value, // text input value
  error, // validation error message
  onChange, // handle on change
  type, // text input type
  label = "", // text input label
  onBlur, // onBlur
  disabled = false, // input is disabled,
  variant = "outlined", // variant
  placeholder = "", // placeholder
  multiple = false, // multiple line
  heightMutliple,
  onFocus,
  backgroundColor,
  min,
  max,
  dateInput,
  defaultValue,
  multiline
}: TextInputPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  // password visibility
  const [show, setShow] = useState<boolean>(false);
  // handle click on password icon
  const handleVisibility = useCallback(() => {
    setShow((prev) => !prev);
  }, [setShow]);
  return (
    <FormControl fullWidth>

          {multiple ? (
            <TextareaAutosize
              onBlur={onBlur}
              placeholder={t(placeholder)}
              value={value || ""}
              onChange={onChange}
              onFocus={onFocus}
              disabled={disabled}
              style={{
                resize: "none",
                height: heightMutliple === undefined ? 100 : heightMutliple,
                whiteSpace: "normal",
                backgroundColor: backgroundColor != null ? backgroundColor : "",
              }}
            />
          ) : type !== "password" ? (
            type === "number" ? (
              <TextField 
              required={required}
              sx={{
                backgroundColor: 'white',
                
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor:  '#B269AE!important',
                  },
                
                
                '&:hover ': {
                  borderColor: 'white',
                },
                '&.Mui-focused ': {
                  borderColor: '#B269AE!important',
                  boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
                  
                },
              },
              }}
                label={label}
                type="number"
                value={value}
                onChange={onChange}
                disabled={disabled}
                defaultValue={defaultValue}
                inputProps={{
                  min: min,  
                  max: max,
                  inputMode: 'numeric'
                }}
              />
            ) : type === "date" ? (
              <InputDateStyle
                id={dateInput?.id}
                type="date"
                value={value}
                onChange={onChange}
                variant="standard"
                InputProps={dateInput?.inputPropos}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : type === "color" ? (
              <div><TextField required={required}
              sx={{
                backgroundColor: 'white',
                
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor:  '#B269AE!important',
                  },
                
                
                '&:hover ': {
                  borderColor: 'white',
                },
                '&.Mui-focused ': {
                  borderColor: '#B269AE!important',
                  boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
                  
                },
              },
              }}
                placeholder={t(placeholder)}
                type="text"
                label={label}
                value={value}
                onChange={onChange}
                disabled={disabled}
                multiline={multiline}
                rows={4}
              />           <input type="color" id="head" name="color" style={{height:"100%"}} onChange={onChange} value={value} />
</div>
            ) : (
              <TextField  required={required}
              sx={{
                backgroundColor: 'white',
                
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor:  '#B269AE!important',
                  },
                
                
                '&:hover ': {
                  borderColor: 'white',
                },
                '&.Mui-focused ': {
                  borderColor: '#B269AE!important',
                  boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
                  
                },
              },
              }}
                placeholder={t(placeholder)}
                type="text"
                label={label}
                value={value}
                onChange={onChange}
                disabled={disabled}
                multiline={multiline}
                rows={4}
              />
            )
          ) : (
            <PasswordStyle
              style={{
                backgroundColor: backgroundColor != null ? backgroundColor : "",
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={t(placeholder)}
              type={show ? "text" : "password"}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        
          {type === "password" && (
            <PasswordIconStyle
              onClick={handleVisibility}
              alt="visibility"
              src={show ? hiddenEye : eye}
            />
          )}
       
  
      {error !== "" && <ErrorStyle>{error}</ErrorStyle>}
    </FormControl>
  );
}
export default Button;
