import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";

const EditSeller = ({
  open,
  setOpen,
  formData,
  setFormData,
  mutation,
  seller,
  pos,
}) => {
  const [selectedID, setSelectedId] = useState("");

  useEffect(() => {
    if (open && seller) {
      const birthDate = seller.birth
        ? new Date(seller.birth).toISOString().split("T")[0]
        : ""; // Convert timestamp to YYYY-MM-DD format

      setFormData({
        first_name: seller.first_name || "",
        last_name: seller.last_name || "",
        email: seller.email || "",
        phone: seller.phone || "",
        gender: seller.gender || "",
        birth: birthDate, // Set the formatted birth date
        point_of_sale_id: seller.point_of_sale_id || "",
      });

      setSelectedId(seller.point_of_sale_id || ""); // Update selectedID state
    }
  }, [open, seller, setFormData]);

  const token = localStorage.getItem("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "point_of_sale_id") {
      setSelectedId(value); // Update selectedID when point_of_sale_id changes
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Ensure the value (ID) is captured
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSend = {
      ...formData,
      birth: new Date(formData.birth).getTime(), // Convert birth to timestamp
      point_of_sale_id: Number(formData.point_of_sale_id), // Ensure point_of_sale_id is a number
    };
    mutation.mutate({ seller: seller.id, data: dataToSend, token });
  };

  if (!open) return null;

  return (
    <div
      className="fixed inset-0 z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75"
        aria-hidden="true"
        onClick={() => setOpen(false)} // Close modal when clicking the overlay
      />

      {/* Modal Content */}
      <div className="flex items-center justify-center min-h-screen p-4">
        <div
          className="relative w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
          onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
        >
          {/* Header */}
          <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30 pb-4">
            <h3
              id="modal-title"
              className="text-lg font-medium leading-6 text-white"
            >
              Modifier le vendeur
            </h3>
            <button
              type="button"
              className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
              onClick={() => setOpen(false)}
            >
              <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 p-6">
            <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 gap-y-4">
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prénom
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  value={formData.first_name || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom de famille
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  value={formData.last_name || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Numéro de téléphone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>

              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Genre
                </label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="">Sélectionnez un genre</option>
                  <option value="male">Homme</option>
                  <option value="female">Femme</option>
                  <option value="other">Autre</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="birth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date de naissance
                </label>
                <input
                  id="birth"
                  name="birth"
                  type="date"
                  value={formData.birth || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="point_of_sale_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Point de vente
                </label>
                <select
                  id="point_of_sale_id"
                  name="point_of_sale_id"
                  value={selectedID} // Use selectedID state
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="">Sélectionnez un point de vente</option>
                  {pos.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-6 flex justify-end gap-6">
              <button
                type="button"
                className="block rounded-md bg-purple-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                onClick={() => setOpen(false)}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="block rounded-md bg-[#b269ae] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
              >
                Modifier
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSeller;
