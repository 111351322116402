import { useTranslation } from "react-i18next";
import {  useCallback, useState,ChangeEvent } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./AddSupplier.style";
import {
  ISuppliersAddingData,
  ISuppliersAddingDataError,
  ISuppliersDialog,
} from "./AddSupplier.types";
import { useNavigate } from "react-router-dom";
import { RouteIdEnum } from "config/enums";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import { useAddSupplierMutation } from "redux/api/suppliers/suppliers";

function AddSupplier({
  openAddingDialog,
  setOpenAddingDialog,
  addFromProduct

}: ISuppliersDialog) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [addingData, setAddingData] = useState<ISuppliersAddingData>({
    id:0,
    company_name:"",
    address: "",
    postal_code: "",
    city: "",
    region:"",
    department:"",
    country:"",
    email:"" ,
    office_phone:"",
    mobile_phone:"",
    website:"",
    contact_last_name:"",
    contact_first_name:"",
    contact_position:"",
    contact_mobile:"",
    contact_email:"",
    contact_office_phone:""
  });
  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
  }, [setOpenAddingDialog]);
  const [addingError, setAddingError] = useState<ISuppliersAddingDataError>({
    id:0,
    company_name:"",
    address: "",
    postal_code: "",
    city: "",
    region:"",
    department:"",
    country:"",
    email:"" ,
    office_phone:"",
    mobile_phone:"",
    website:"",
    contact_last_name:"",
    contact_first_name:"",
    contact_position:"",
    contact_mobile:"",
    contact_email:"",
    contact_office_phone:""
  });



  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingData({ ...addingData, [field]: e.target.value });
        if(field=="postal_code"||field=="office_phone"||field=="mobile_phone"||field=="contact_mobile"||field=="contact_office_phone"){
          if(isNaN(parseInt(e.target.value))){
            console.log("Nan")
          setAddingData({ ...addingData, [field]: ""})
          }
        else
        setAddingData({ ...addingData, [field]: e.target.value})


        
        }
      },
    [addingData]
  );





  //addling dialog

  const isValidate = () => {
    let ok = true;
    let addingDataError = {
        id:0,
        company_name:"",
        address: "",
        postal_code: "",
        city: "",
        region:"",
        department:"",
        country:"",
        email:"" ,
        office_phone:"",
        mobile_phone:"",
        website:"",
        contact_last_name:"",
        contact_first_name:"",
        contact_position:"",
        contact_mobile:"",
        contact_email:"",
        contact_office_phone:""
    };
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    if (addingData.company_name === "") {
      ok = false;
      addingDataError.company_name = t("supplier.company_name_required");
    }
    else {
      addingDataError.company_name = ""

    }

    if (addingData.postal_code=="") {
      ok = false;
      addingDataError.postal_code= t("supplier.postal_code_required");
    }
    else {
      addingDataError.postal_code = ""

    }
    if (addingData.office_phone=="") {
      ok = false;
      addingDataError.office_phone = t("supplier.office_phone_required");
    }
    else {
      addingDataError.office_phone = ""

    }
    if (addingData.mobile_phone=="") {
      ok = false;
      addingDataError.mobile_phone = t("supplier.mobile_phone_required");
    }
    else
    {
      addingDataError.mobile_phone = ""

    }
    if (addingData.contact_mobile=="") {
      ok = false;
      addingDataError.contact_mobile = t("supplier.contact_mobile_required");
    }
    else{
    addingDataError.contact_mobile = ""
    }
    if(!urlPattern.test(addingData.website)){
      addingDataError.website="URL invalide"
    }
    else {
      addingDataError.website=""

    }







    if (!ok) {
      setAddingError(addingDataError);
    }

    return ok;
  };



  const [addingSupplier ,{ data, error, reset,isLoading }] = useAddSupplierMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("supplier.successfully_adding"));
    reset();
    !addFromProduct ?navigate("/" + RouteIdEnum.Supplier):navigate("/products/add");
  }
  const addSupplierForm = (event:any) => {
    event.preventDefault();
    console.log("isValid",isValidate())
    if (isValidate()) {

      addingSupplier(addingData);
      closeAddingDialog()
      setAddingData({id:0,
      company_name:"",
      address: "",
      postal_code: "",
      city: "",
      region:"",
      department:"",
      country:"",
      email:"" ,
      office_phone:"",
      mobile_phone:"",
      website:"",
      contact_last_name:"",
      contact_first_name:"",
      contact_position:"",
      contact_mobile:"",
      contact_email:"",
      contact_office_phone:""
      })
      setAddingError({id:0,
        company_name:"",
        address: "",
        postal_code: "",
        city: "",
        region:"",
        department:"",
        country:"",
        email:"" ,
        office_phone:"",
        mobile_phone:"",
        website:"",
        contact_last_name:"",
        contact_first_name:"",
        contact_position:"",
        contact_mobile:"",
        contact_email:"",
        contact_office_phone:""
        })

    }

  };





  return (

    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("supplier.add")}
    >
        <InputStyle>

        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.company_name}
            label={t("supplier.company_name")}
            onChange={onChange("company_name")}
            error={t(addingError.company_name)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.address}
            label={t("supplier.address")}
            onChange={onChange("address")}
            error={t(addingError.address)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.postal_code}
            label={t("supplier.postal_code")}
            type="number"
            onChange={onChange("postal_code")}
            error={t(addingError.postal_code)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.city}
            label={t("supplier.city")}
            onChange={onChange("city")}
            error={t(addingError.city)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.region}
            label={t("supplier.region")}
            onChange={onChange("region")}
            error={t(addingError.region)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.department}
            label={t("supplier.department")}
            onChange={onChange("department")}
            error={t(addingError.department)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.country}
            label={t("supplier.country")}
            onChange={onChange("country")}
            error={t(addingError.country)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.email}
            label={t("supplier.email")}
            onChange={onChange("email")}
            type="email"
            error={t(addingError.email)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.office_phone}
            label={t("supplier.office_phone")}
            onChange={onChange("office_phone")}
            type="number"
            error={t(addingError.office_phone)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.mobile_phone}
            label={t("supplier.mobile_phone")}
            onChange={onChange("mobile_phone")}
            type="number"
            error={t(addingError.mobile_phone)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.website}
            type="url"
            label={t("supplier.website")}
            onChange={onChange("website")}
            error={t(addingError.website)}

          />
        </InputStyle>
        <span>Contact</span>
        <InputStyle>
          <TextInput
            value={addingData.contact_last_name}
            label={t("supplier.contact_last_name")}
            onChange={onChange("contact_last_name")}
            error={t(addingError.contact_last_name)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.contact_first_name}
            label={t("supplier.contact_first_name")}
            onChange={onChange("contact_first_name")}
            error={t(addingError.contact_first_name)}

          />
        </InputStyle>

        <InputStyle>
          <TextInput
            value={addingData.contact_position}
            label={t("supplier.contact_position")}
            onChange={onChange("contact_position")}
            error={t(addingError.contact_position)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.contact_mobile}
            label={t("supplier.contact_mobile")}
            type="number"
            onChange={onChange("contact_mobile")}
            error={t(addingError.contact_mobile)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.contact_email}
            label={t("supplier.contact_email")}
            onChange={onChange("contact_email")}
            type="email"
            error={t(addingError.contact_email)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.contact_office_phone}
            label={t("supplier.office_phone")}
            type="number"
            onChange={onChange("contact_office_phone")}
            error={t(addingError.contact_office_phone)}

          />
        </InputStyle>

        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              onClick={addSupplierForm}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
    </Dialog>
  );
}
export default AddSupplier;
