import useCategoryList from "features/Category/CategoryList/useCategoryList";
import { ChangeEvent, useCallback, useEffect, useState,SyntheticEvent } from "react";
import {
  IProductData,
  IProductDataEditingImage,
  IProductDataEditingImageError,
  IProductDataEditingError,
  IProductItemDataImage,
  IUseEditProps,
} from "./ProductEdit.types";
import { useTranslation } from "react-i18next";
import { isNumber } from "utils/helpers/global.helpers";
import { useNavigate } from "react-router-dom";
import {
  useGetProductQuery,
  useUpdateProductMutation,
} from "redux/api/product/product";
import { RouteIdEnum } from "config/enums";
import { useSnackBar } from "hooks/useSnackBar";
import { useGetAllSubCategoriesQuery } from "redux/api/category/category";

export default function useEditProductList({ id }: IUseEditProps) {
 const { t } = useTranslation();
  const navigate = useNavigate();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const { categoryData } = useCategoryList();
  const [compteurImages, setCompteurImages] = useState(1);
  const {
    data: productItemData,
    isSuccess,
    refetch: refetchProductItem,
  } = useGetProductQuery(Number(id));
  console.log("product",productItemData?.nutritional_values.map(({ id, name, value }:any) => ({ id, name, value })) || [])

  const [editingData, setEditingData] = useState<IProductData>({
    images:productItemData?.images,
    title: productItemData?.title,
    price:productItemData?.price,
    description: productItemData?.description,
    purchase_price: productItemData?.purchase_price,
    quantity: productItemData?.quantity,
    category_product_id: productItemData?.category_product_id,
    is_images_updated: productItemData?.is_images_updated,
    subCategory_product_id:productItemData?.Subcategory_product_id,
    tax_id:{id: productItemData?.taxes[0]?.id,name: productItemData?.taxes[0]?.name,percentage: productItemData?.taxes[0]?.percentage},
    color_hex:productItemData?.color_hex,
    price_ttc:productItemData?.price_ttc,
    barcode:productItemData?.barcode,
    discount:productItemData?.discount,
    label:productItemData?.label,
    status:productItemData?.status,
    publication_date:productItemData?.publication_date,
    display:productItemData?.display,
    nutri_score:productItemData?.nutri_score,
    stock_alert:productItemData?.stock_alert,
    min_qty:productItemData?.min_qty,
    color:productItemData?.color,
    nutritional_values:productItemData?.nutritional_values.map(({ id, name, value }:any) => ({ id, name, value })) || [],
    provider_id:productItemData?.provider_id,
    marge:productItemData?.marge,
    categ_exped:{id:productItemData?.categ_exped,label:productItemData?.categ_exped_name},
    keyWords:[productItemData?.keyWords],
    format:productItemData?.format,
    brand:productItemData?.brand_id,

  });

  const [editingError, setEditingError] =


    useState<IProductDataEditingError>({
      title: "",
      color:"",
      description: "",
      price: "",
      quantity: "",
      category_product_id: "",
      images: "",
      purchase_price:"",
      subCategory_product_id:"",
      tax_id:'',
      color_hex:"",
      price_ttc:"",
      barcode:"",
      discount:"",
      label:"",
      status:"",
      publication_date:"",
      display:"",
      nutri_score:"",
      stock_alert:"",
      min_qty:"",
      provider_id:"",
      nutritional_values:[],
      marge:"",
      categ_exped:"",
      keyWords:[],
      brand:""
      
    });
    const {data:subcategories,isLoading}=useGetAllSubCategoriesQuery({id:editingData?.category_product_id})
    const [subCategs,setSubCategs]=useState([])
  const [addingImage, setAddingImage] = useState<IProductDataEditingImage[]>([]);
  useEffect(() => {
    if (productItemData && isSuccess) {
      setEditingData({
        title: productItemData?.title?productItemData?.title:"",
        description: productItemData?.description?productItemData?.description:"",
        price: productItemData?.price?productItemData?.price:"",
        quantity: productItemData?.quantity?productItemData?.quantity:"",
        category_product_id: productItemData?.category_product_id?productItemData?.category_product_id:"",
        is_images_updated: 0,
        purchase_price:productItemData?.purchase_price?productItemData?.purchase_price:"",
        subCategory_product_id:productItemData?.Subcategory_product_id?productItemData?.Subcategory_product_id:"",
        tax_id:productItemData?.taxes?{id: productItemData?.taxes[0]?.id,name: productItemData?.taxes[0]?.name,percentage: productItemData?.taxes[0]?.percentage}:{ id: 0, name: "Taxe", percentage: "" },
        color_hex:productItemData?.color_hex?productItemData?.color_hex:"",
        price_ttc:`${parseFloat(productItemData.price ?? 0) + ((parseFloat(productItemData.price ?? 0) * parseFloat(productItemData.tax_id?.percentage ?? "0"))/100)}`,
        barcode:productItemData?.barcode?productItemData?.barcode:"",
        discount:productItemData?.discount?productItemData?.discount:"",
        label:productItemData?.label?productItemData?.label:"",
        status:productItemData?.status?productItemData?.status:"",
        publication_date:productItemData?.publication_date?productItemData?.publication_date:"",
        display:productItemData?.display?productItemData?.display:"",
        nutri_score:productItemData?.nutri_score?productItemData?.nutri_score:"",
        stock_alert:productItemData?.stock_alert?productItemData?.stock_alert:"",
        min_qty:productItemData?.min_qty?productItemData?.min_qty:"",
        color:productItemData?.color?productItemData?.color:"",
        nutritional_values:productItemData?.nutritional_values.map(({ id, name, value }:any) => ({ id, name, value })) || [],
        provider_id:productItemData?.provider_id?productItemData?.provider_id:"",
        marge:productItemData?.marge?productItemData?.marge:"",
        categ_exped:productItemData?.categ_exped?{id:productItemData?.categ_exped,label:productItemData?.categ_exped_name}:{id:0,label:""},
        keyWords:productItemData?.keyWords?productItemData?.keyWords:"",
        images: [],
        format:productItemData?.format?productItemData?.format:"",
        brand:productItemData?.brand_id?productItemData?.brand_id:"",
      });
      productItemData.image_products?.map((item: IProductItemDataImage) => {

        setAddingImage((prevState:any) => [
          ...prevState,
          {
            id:item.id,
            color_name: item.color_name,
            color_hex: item.color_hex,
            is_main_image: item.is_main_image,
            file: item.image,
          },
        ]);
        if(typeof item.id == 'number' && item.id>compteurImages){
          setCompteurImages(item.id+1)
        }
      });
    }
  }, [isSuccess, productItemData]);
  const handleDeleteImage=(itemData:any)=>{
    console.log("itemData",itemData)
   setAddingImage((prevState:any)=>prevState.filter((item:any) => item.id !== itemData.id))

  }
  const onChangeSelectCategory = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, category_product_id: e });
/*       validateWhenChange()
 */      setSubCategs(subcategories)
 setEditingError({ ...editingError, category_product_id: "" });
    },
    [editingData, editingError]
  );
  const onChangeSelectShow = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, display: e });
      setEditingError({ ...editingError, display: "" });
   },
    [editingData, editingError]
  );
  const onChangeSelectProvider = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, provider_id: e });
      setEditingError({ ...editingError, provider_id: "" });
    },
    [editingData, editingError]
  );
  const onChangeSelectBrand = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, brand: e });
      setEditingError({ ...editingError, brand: "" });
    },
    [editingData, editingError]
  );
  const onChangeSelectCategExped = (event: SyntheticEvent<Element,Event>, newValue: {id:string,label:string}|null )=>{
    console.log("newValue",newValue)
      setEditingData({ ...editingData, categ_exped: newValue });
      setEditingError({ ...editingError, categ_exped: "" });
    }
    const onChangeSelectSubCategory = useCallback(
      (field: string) => (e: string) => {
        setEditingData({ ...editingData, subCategory_product_id: e });
        setEditingError({ ...editingError, subCategory_product_id:"" });
      },
      [editingData, editingError]
    );
    const onChangeSelectStatus = useCallback(     (field: string) => (e: string) => {
        setEditingData({ ...editingData, status: e });
        setEditingError({ ...editingError, status: "" });
      },
      [editingData, editingError]
    );
  const [isImagesUpdated, setIsImagesUpdated] = useState<number>(0);
  const checkImagesUpdated = useCallback(() => {
    if (!isImagesUpdated) {
      setIsImagesUpdated(1);
     setAddingImage([]);
    }
  }, [isImagesUpdated]);
  const [addingOneImage, setAddingOneImage] = useState<any>(
    {
      id:0,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    }
  );
  const onChangeKeyWords=(e:any,keyWords:any)=>{
    setEditingData({ ...editingData,keyWords:keyWords});
    setEditingError({ ...editingError, keyWords: []});
  }
    const onChangeSelectTax =(e: any,newValue:{id:number,name:string,percentage:string}) => {
        setEditingData({ ...editingData, tax_id: newValue});
        setEditingError({ ...editingError, tax_id: '' });
      }
    const onChangeSelectNutriscore = useCallback(
      (field: string) => (e: string) => {
        setEditingData({ ...editingData, nutri_score: e });
        setEditingError({ ...editingError, nutri_score: "" });
      },
      [editingData, editingError]
    );
  const [isMainImageSelected, setIsMainImageSelected] =
    useState<boolean>(false);
  const [addingOneImageError, setAddingOneImageError] =
    useState<IProductDataEditingImageError>({
      color_name: "",
      color_hex: "",
      file: "",
    });
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        /* setEditingError(prevError => ({ 
          ...prevError, 
          [field]: editingData.categ_exped ? editingData.categ_exped.id : "" 
        })); */
        
        setEditingData({ ...editingData, [field]: e.target.value });
        
      },
    [editingData]
  );
  const onChangeOneImage = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingOneImage({
          ...addingOneImage,
          [field]: e.target.value,
        });
        setAddingOneImageError({
          color_name: "",
          color_hex: "",
          file: "",
        });
        if (field === "is_main_image") {
          setAddingOneImage({
            ...addingOneImage,
            is_main_image: addingOneImage.is_main_image ? 0 : 1,
          });
        }
        if (field === "file") {
          const files = (e.target as HTMLInputElement).files;
          if (files)
            setAddingOneImage({
              ...addingOneImage,
              file: files[0],
            });
        }
      },
    [addingOneImage]
  );
  const onSaveImage = useCallback(() => {
    setIsImagesUpdated(1);
    const newAddingOneImage = {
      ...addingOneImage,
      id: compteurImages + 1,
    };

    setAddingImage((prevState) => [...prevState, newAddingOneImage]);

    if (newAddingOneImage.is_main_image) {
      setIsMainImageSelected(true);
    }

  
    setAddingOneImage({
      id: compteurImages + 1,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    });

    // Increment compteurImages state
    setCompteurImages(compteurImages + 1);

    // Reset addingOneImageError state
    setAddingOneImageError({
      color_name: "",
      color_hex: "",
      file: "",
    });
  }, [addingOneImage, checkImagesUpdated]);
  //addling dialog
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogContainer = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);
  const closeAddingDialogContainer = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingOneImage({
      id:compteurImages,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    });
    setAddingOneImageError({
      color_name: "",
      color_hex: "",
      file: "",
    });
  }, [setOpenAddingDialog]);
  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    if (isImageValidate()) {
      onSaveImage();
      closeAddingDialogContainer();
    }
  };
  const isImageValidate = () => {
    let ok = true;
    let addingImg = {
      color_name: "",
      color_hex: "",
      file: "",
    };
    // if (addingOneImage.color_hex === "") {
    //   ok = false;
    //   addingImg.color_hex = t("product.color_hex_required");
    // }
    // if (addingOneImage.color_name === "") {
    //   ok = false;
    //   addingImg.color_name = t("product.color_name_required");
    // }
    if (addingOneImage.file === null) {
      ok = false;
      addingImg.file = t("product.file_required");
    }
    if (!ok) {
      setAddingOneImageError(addingImg);
    }
    return ok;
  };
  const isValidate = () => {
    let ok = true;
    let addingProduct: any = {
      title: "",
      description: "",
      price: "",
      quantity: "",
      category_product_id: "",
      images: "",
      subCategory_product_id: "",
      tax_id: "",
      
      status: "",
      publication_date: "",

      nutriscore: "",
      provider_id: "",
      marge: "",
      purchase_price:"",
      price_ttc:"",
      barcode:"",
      discount:"",

      label:"",
      display:[],
      nutri_score:"",
      stock_alert:"",
      min_qty:"",
      nutritional_values:[],
      categ_exped:"",
      keyWords:[],
      brand:""
    };
    if (editingData.title === "") {
      ok = false;
      addingProduct.title = t("product.title_required");
    } else addingProduct.title = "";
    if(editingData.status!="1"){
    if (editingData.description === "") {
      ok = false;
      addingProduct.description = t("product.description_required");
    } else addingProduct.description = "";

    if (!isNumber(editingData.price) || Number(editingData.price) <= 0) {
      ok = false;
      addingProduct.price = t("product.price_required");
    } else {
      addingProduct.price = "";
    }

    if (!editingData.category_product_id) {
      ok = false;
      addingProduct.category_product_id = t(
        "product.category_product_id_required"
      );
    } else {
      addingProduct.category_product_id = "";
    }
    if (!editingData.subCategory_product_id) {
      ok = false;
      addingProduct.subCategory_product_id = t(
        "product.subCategory_product_id_required"
      );
    } else {
      addingProduct.subCategory_product_id = "";
    }
    if (editingData.purchase_price === "") {
      ok = false;
      addingProduct.purchase_price = t("product.price_HT_required");
    } else {
      addingProduct.purchase_price = "";
    }
    if (!editingData.tax_id ) {
      ok = false;
      addingProduct.tax_id = 'Taxe obligatoire';
    } else {
      addingProduct.tax_id = "";
    }
     if (!editingData.brand  || editingData.brand == "") {
      ok = false;
      addingProduct.brand = "Marque obligatoire";
    } else {
      addingProduct.brand = "";
    } 
    
   /*  if (editingData.stock_alert === "") {
      ok = false;
      addingProduct.stock_alert = t("product.limit_stock_required");
    } else {
      addingProduct.stock_alert = "";
    } */
    /* if (editingData.min_qty === "") {
      addingProduct.min_qty = t("product.min_quantity_required");
    } else {
      addingProduct.stock_alert = "";
    } */
    if (editingData.quantity === "") {
      ok = false;
      addingProduct.quantity = t("product.quantity_required");
    } else {
      addingProduct.quantity = "";
    }
    if (editingData.marge === "") {
      ok = false;
      addingProduct.marge = t("product.marge_required");
    } else {
      addingProduct.marge = "";
    }
    if (editingData.category_product_id && !editingData.subCategory_product_id) {
      ok = false;
      addingProduct.subCategory_product_id = t("product.must_have_subcat");
    }

    if (
      (editingData.discount && parseFloat(editingData.discount) < 0) ||
      parseFloat(editingData.discount) > 100
    ) {
      ok = false;
      addingProduct.discount = t("product.discount_range");
    } else {
      addingProduct.discount = "";
    }
   /*  if (editingData.min_qty == "") {
      ok = false;
      addingProduct.min_qty = t("product.min_quantity_required");
    } */

    if (
      parseInt(editingData.min_qty) < 0 ||
      parseInt(editingData.min_qty) > 100
    ) {
      ok = false;
      addingProduct.min_qty = t("product.min_quantity_range");
    } else {
      addingProduct.min_qty = "";
    }
   /*  if (!editingData.stock_alert) {
      ok = false;
      addingProduct.stock_alert = t("product.limit_stock_required");
    } else {
      addingProduct.stock_alert = "";
    } */
    if (
      parseInt(editingData.stock_alert) < 0 ||
      parseInt(editingData.stock_alert) > 100
    ) {
      ok = false;

      addingProduct.stock_alert = t("product.limit_stock_range");
    } else {
      addingProduct.stock_alert = "";
    }

    if (editingData.barcode == "") {
      ok = false;
      addingProduct.barcode = t("product.bar_code_required");
    } else {
      addingProduct.barcode = "";
    }
    
    if (editingData.status === "3" && editingData.publication_date === "") {
      ok = false;
      addingProduct.publication_date = t("product.publication_date_required");
    } else {
      addingProduct.publication_date = "";
    }

    if (editingData.status == "") {
      ok = false;
      addingProduct.status = t("product.status_required");
    } else {
      addingProduct.status = "";
    }
    if (editingData.display.length == 0) {
      ok = false;
      addingProduct.display.push(t("product.show_required"));
    } else {
      addingProduct.display = [];
    }
    if (!editingData.categ_exped) {
      ok = false;
      addingProduct.categ_exped = "Catégorie expédition obligatoire";
    } else {
      addingProduct.categ_exped = "";
    }
    
    if (editingData.provider_id == "") {
      ok = false;
      addingProduct.provider_id = t("product.provider_id_required");
    } else {
      addingProduct.provider_id = "";
    }
    if (editingData.images.length === 0) {
      ok = false;
      addingProduct.images = "image is required";
    } else {
      addingProduct.images = "";
    }
  }

    console.log("addingError", addingProduct);
    console.log("editingData", editingData);
    

    if (!ok) {
      setEditingError(addingProduct);
    }

    return ok;
  };

  const [updateProduct, { data, error, reset }] = useUpdateProductMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_editing"));
    reset();
    refetchProductItem();
    navigate("/" + RouteIdEnum.Products);
  }
  const addProductForm = () => {
    editingData.images = [];
      addingImage.map((item, index) => {

        editingData.images.push({file:item.file, is_main_image:item.is_main_image,
              color_hex:item.color_hex,
              id:item.id,
              color_name : item.color_name})

      });
    if (isValidate()) {
      console.log('terfer',editingData)
      
      const formData = new FormData();

      formData.append("title", editingData.title);
      formData.append("description", editingData.description);
      formData.append("price", editingData.price);
      formData.append("quantity",editingData.quantity);
      formData.append("category_product_id",editingData.category_product_id);
      formData.append("purchase_price",editingData.purchase_price);
      editingData.subCategory_product_id ?formData.append("Subcategory_product_id",editingData.subCategory_product_id):formData.append("Subcategory_product_id","");
      editingData.tax_id?.id?formData.append("tax_id", `${editingData.tax_id?.id}`):formData.append("tax_id", "");
      formData.append("discount", editingData.discount);
      formData.append("price_ttc",`${parseFloat(editingData.price ?? 0) + ((parseFloat(editingData.price ?? 0) * parseFloat(editingData.tax_id?.percentage ?? "0"))/100)}`,);
      formData.append("barcode",editingData.barcode);
      formData.append("is_images_updated",`${isImagesUpdated}`);
      formData.append("label", editingData.label);
      formData.append("status",editingData.status);
      formData.append("display", JSON.stringify(editingData.display));
      formData.append("nutri_score",editingData.nutri_score);
      formData.append("stock_alert", editingData.stock_alert);
      formData.append("min_qty", editingData.min_qty);
      formData.append("marge",editingData.marge);
      formData.append("format",editingData.format );
      formData.append("brand",editingData.brand);
      editingData.categ_exped?formData.append("categ_exped", editingData.categ_exped.id):formData.append("categ_exped", "");

      editingData.nutritional_values.forEach((nutritional_value: any, index: number) => {
        const nutritionalname = nutritional_value.name;
        const nutritionalvalue = nutritional_value.value;


        formData.append("nutritional_values[" + index + "][name]", nutritionalname);

        formData.append("nutritional_values[" + index + "][value]", nutritionalvalue);

      });
      editingData.keyWords.forEach((keyWord: any, index: number) => {




        formData.append("keyWords[" + index + "]", keyWord);

      });
      formData.append("provider", editingData.provider_id);
      // Handle file uploads for the images field
      editingData.images.forEach((image: any, index: number) => {
        const file = image.file;
        const isMainImage = image.is_main_image;
        const colorHex = image.color_hex;
        const colorName = image.color_name;

        formData.append("images[" + index + "][file]", file);
        formData.append("images[" + index + "][is_main_image]", isMainImage);
        formData.append("images[" + index + "][color_hex]", colorHex);
        formData.append("images[" + index + "][color_name]", colorName);
      });

     
    updateProduct({id:id,formData:formData});
  }
}


  return {
    categoryData,
    editingData,
    editingError,
    onChange,
    onChangeSelectCategory,
    openAddingDialogContainer,
    addingImage,
    addProductForm,
    openAddingDialog,
    closeAddingDialogContainer,
    onSuccessAddingDialog,
    addingOneImage,
    onChangeOneImage,
    addingOneImageError,
    isMainImageSelected,
    productItemData,
    setEditingData,
    onChangeSelectSubCategory,
    onChangeSelectTax,
    onChangeSelectProvider,
    onChangeSelectShow,
    onChangeSelectCategExped,
    onChangeKeyWords,
    onChangeSelectStatus,
    onChangeSelectNutriscore,
    setEditingError,
    subcategories,
    handleDeleteImage,
    onChangeSelectBrand,
    isSuccess
  };
}
