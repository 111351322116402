import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { ListItems } from './Menu.type';
import { Settings } from '@mui/icons-material';


export default function MenuItems(list:ListItems) {
  const [anchorEl, setAnchorEl] = React.useState<any>(list.anchorEl);
  const open = Boolean(list.anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(()=>{
    console.log("open",list.anchorEl)
  },[list.anchorEl])
  return (
    <React.Fragment>
   <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
               <Tooltip title="Status">
               <IconButton
 onClick={(event:any)=>{list.handleClick(event,list.currentOrder)}}          
   size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
                <Settings 
 />  
 </IconButton>
 </Tooltip>
 </Box>
          <Menu
      anchorEl={list.anchorEl}
      onClick={(event:any)=>list.handleClick(list.currentOrder,event)}
      id="account-menu"
      open={list.open}           
       onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
         
       
          >
          {open &&list.selectedRecord&&list.currentOrder.id===list.selectedRecord.id &&[{ id: 0, name: "Annulé" },{ id: 1, name: "Paiement en attente" }, { id: 2, name: "En cours de traitement" }, { id: 3, name: "En cours de livraison" }, { id: 4, name: "Livré" }].map((item:any)=>(
                 <MenuItem key={item.id} onClick={()=> list.selectedRecord&&list.onChange(item.id,list.selectedRecord.id)} >
                 {item.name}
                  </MenuItem>
          ))}
         
          </Menu>
    </React.Fragment>
  );
}