import { Paginator } from "types/interfaces";

// Inject a paginator in a request params
export const injectPaginator = (url: string, paginator?: Paginator,all?:boolean,isAdmin?:boolean,category?:any,Subcategory?:any,Stock?:any) => {
  if (paginator) {
    const { page, search, perPage, orderBy, orderDir } = paginator;
    let path = `${url}?page=${page}&perPage=${perPage}`;
    if (orderBy) {
      path = `${path}&orderBy=${orderBy}`;
    }
    if (orderDir) {
      path = `${path}&orderDirection=${orderDir}`;
    }
    if (search) {
      path = `${path}&search=${search}`;
    }
    if (all) {
      path = `${path}&all=${all}`;
    }
    if (isAdmin) {
      path = `${path}&isAdmin=${isAdmin}`;
    }
    if (category) {
      path = `${path}&categ=${category}`;
    }
     if (Subcategory) {
      path = `${path}&Subcategory=${Subcategory}`;
    }
    if (Stock) {
      path = `${path}&Stock=${Stock}`;
    }
    return path;
  } else {
    return url;
  }
};

export const arrayToParams = (key: string, array: Array<number | string>) => {
  let string = "";
  array.forEach((value) => {
    string += `${key}[]=${value}&`;
  });
  return string;
};
