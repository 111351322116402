import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "config/constant";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";
import { IPromotionFlashAddingData } from "features/FlashPromotion/PromotionFlashAdd/AddFlashPromotion.types";
export const promotionFlashsApi = createApi({
    reducerPath: "PromotionFlashsApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["PromotionFlashs"],
    endpoints: (builder) => ({
      getPromotionFlashById: builder.query({
        query: (id: string) => ENDPOINTS.PROMOTION_FLASH + id,
      }),
      getAllPromotionFlashs: builder.query({
        query: (params: { paginator?: Paginator }) => {
          return injectParamsPaginator(
            ENDPOINTS.PROMOTION_FLASH,
            params.paginator
          );
        },
        providesTags: ["PromotionFlashs"],
      }),
      deletePromotionFlash: builder.mutation({
        query: (id: number) => ({
          url: `${ENDPOINTS.PROMOTION_FLASH}/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["PromotionFlashs"],
      }),
      deleteMultiplePromotionFlashs: builder.mutation({
        query: (params: { promo_flash_ids: number[], select_all: boolean ,search:string}) => ({
          url: `${ENDPOINTS.PROMOTION_FLASH}/delete-multiple`,
          method: "POST",
          body: {
            promo_flash_ids: params.promo_flash_ids,
            select_all: params.select_all,
            search:params.search
          },
        }),
        invalidatesTags: ["PromotionFlashs"],
      }),
      addPromotionFlash: builder.mutation({
        query: ({
            description,
            start_date,
            end_date,
            link,
            categories,
            subCategories,
            products,
            subscriptions,
            days,
            hours,
            minutes,
            seconds,
            percentage_value,
            eur_value,
            value_type,
            promotion_value,
  
        }: IPromotionFlashAddingData) => ({
          url: `${ENDPOINTS.PROMOTION_FLASH}`,
          method: "POST",
          body: {  description,
            start_date,
            end_date,
            link,
            categories,
            subCategories,
            products,
            subscriptions,
            days,
            hours,
            minutes,
            seconds,
            percentage_value,
            eur_value,
            value_type,
            promotion_value,
        },
        }),
        invalidatesTags: ["PromotionFlashs"],
      }),
      updatePromotionFlash: builder.mutation({
        query: ({
      id,
      description,
            start_date,
            end_date,
            link,
            categories,
            subCategories,
            products,
            percentage_value,
            eur_value,
            value_type,promotion_value
        }: IPromotionFlashAddingData) => ({
          url: `${ENDPOINTS.PROMOTION_FLASH}/${id}`,
          method: "POST",
          body: {
            description,
            start_date,
            end_date,
            link,
            categories,
            subCategories,
            products,
            percentage_value,
            eur_value,value_type,promotion_value },
        }),
        invalidatesTags: ["PromotionFlashs"],
      }),
    }),
  });
  export const {
    useGetAllPromotionFlashsQuery,
    useGetPromotionFlashByIdQuery,
    useDeletePromotionFlashMutation,
    useAddPromotionFlashMutation,
    useUpdatePromotionFlashMutation,
    useDeleteMultiplePromotionFlashsMutation
  } = promotionFlashsApi;
  