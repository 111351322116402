import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import {  IExpeditionModesData } from "./ExpeditionModes.types";
import { Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import StatusBadge from "components/common/StatusBadge/StatusBadge";
import { isTimeStampsDateBetweenTwoDays } from "utils/helpers/global.helpers";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
} from "./ExpeditionModes.style";
import ExpeditionCategories from "features/ExpeditionCategory/ExpeditionCategoryList/ExpeditionCategories";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import usePromotionCodesList from "../../PromotionCodes/PromotionCodesList/usePromotionCodesList";
import DeleteExpeditionMode from "../ExpeditionModeDelete/DeleteExpeditionMode";
import { useCallback, useEffect, useState } from "react";
import AddExpeditionMode from "../ExpeditionModeAdd/AddExpeditionMode";
import EditExpeditionMode from "../ExpeditionModeEdit/EditExpeditionMode";
import { useNavigate } from "react-router-dom";
import useExpeditionModeList from "./useExpeditionModeList";
import {Checkbox} from "@mui/material";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import DeleteExpeditionCategoryMultiple from "../ExpeditionModeMultipleDelete/DeleteExpeditionModeMultiple";
import { useDeleteMultipleExpeditonModesMutation } from "redux/api/delivery/expeditionMode/expeditionMode";
import { useSnackBar } from "hooks/useSnackBar";
function ExpeditionModes() {
  const { t } = useTranslation();
  const [selectedShippingMode,setSelectedShippingMode]=useState(0);

  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    expeditionModes,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
  } = useExpeditionModeList();
  const [openDialog,setOpenDialog]=useState(false)

  const openDialogStatus = useCallback(
    (
      id: number,
    ) =>
    () => {
      console.log(id)
      setOpenDialog(true);
      setSelectedElement({id})
  }, []);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openDeleteMultipleModal,setOpenDeleteMultipleModal]=useState(false)

  const [deletedCode, setDeletedCode] = useState<{
    id: number;
    title: string;
  }>();

  const openDeleteDialogStatus = useCallback(
    (id: number, title: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedCode({ id: id, title: title });
    },
    [setOpenDeleteDialog, setDeletedCode]
  );
 const [selectedElement,setSelectedElement]=useState<{id:number}|null>()
 const navigate=useNavigate()
  const [deleteCode,{data,error,isLoading:loading,reset}]=useDeleteMultipleExpeditonModesMutation()
  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedCodeInfo, setEditedCodeInfoCode] = useState<{
    id: number;
    title:string;
    start: string;
    end:string;
    tax_value:string;
    categ_values:any;
  }>();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();


  const openEditingDialogStatus = useCallback(
    (
        id: number,
        title:string,
        start: string,
        end:string,
        categ_values:any,
        tax_value:string

      ) =>
      () => {
        setEditedCodeInfoCode({ id, title,start,end,tax_value,categ_values });
        setOpenEditingDialog(true);
      },
    []
  );
  const [checkedItems,setCheckedItems]=useState<number[]>([])
  const handleChangeCheckbox=(event:React.ChangeEvent<HTMLInputElement>,id:number)=>{
    if(event.target.checked){
    setCheckedItems((prevState:number[])=>([...prevState,id]))
    }
    else {
      setCheckedItems((prevState:number[])=>(prevState.filter((item:number)=>(item!==id))))

    }
  }
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };
  const openDeleteMultipleDialogStatus =() => {
    setOpenDeleteMultipleModal(true);

};

if (error) {
  displayErrorApiSnackBar(error);
  reset();
}
if (data) {
  displaySuccessSnackBar(t("delivey.successfully_deleted_mod"));
  reset();

}

  const deleteMultiple=(event:any)=>{
    event.preventDefault()
   deleteCode({
      mode_ids:selectedItems,
      select_all:selectAll,
      search:search
  })
  if(!error){
  setOpenDeleteMultipleModal(false)
  reset()
  }
  }
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  return (
    <DashboardLayout
      title={t("menu.mode_exped")}
      selectedPath={RouteIdEnum.Expedition_Mode}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <ButtonContainerStyle item xs={12} sm={4}>
        {((selectAll || selectedItems.length != 0) )&&<DeleteButton onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
       }
         &nbsp;
                   <AddButton
            onAddClick={()=>{navigate("add")}}
            title={t("common.add")}
          />
        </ButtonContainerStyle>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: <Checkbox  color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "mode",
            title: t("delivery.mode"),

            onClick: onOrderChange("mode"),
            asc: orderBy === "mode" ? orderDir === "asc" : undefined,
          },
          {
            key: "start_time",
            title: t("delivery.start_time"),
            onClick: onOrderChange("start_time"),
            asc: orderBy === "start_time" ? orderDir === "asc" : undefined,
          },
          {
            key: "end_time",
            title: t("delivery.end_time"),
            onClick: onOrderChange("end_time"),
            asc: orderBy === "end_time" ? orderDir === "asc" : undefined,
          },
          {
            key: "tax",
            title: t("delivery.tax"),
            onClick: onOrderChange("tax"),
            asc: orderBy === "tax" ? orderDir === "asc" : undefined,
          },
          {
            key: "cat_exp",
            title: "catégorie d'expédition",
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={expeditionModes?.data.map((e: IExpeditionModesData) => {
          return {
            checkbox: (
              <Checkbox  checked={(!selectAll && selectedItems.includes(e.id))||(selectAll && !selectedItems.includes(e.id))}
              onChange={() => handleCheckboxChange(e.id)}
              />

            ),
                        mode: <p>{e.title}</p>,
            start_time: <p>{e.start}</p>,
            end_time: <p>{e.end}</p>,
            tax: <p>{e.tax_value}</p>,
            cat_exp: (
              <ActionContainerStyle>
                {
                  <Tooltip title={t("common.show")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDialogStatus(e.id)}

                    >
                      <RemoveRedEye />
                    </IconButton>
                  </Tooltip>
                }

              </ActionContainerStyle>
            ),
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={()=>navigate(`edit/${e.id}`)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.title)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: expeditionModes?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />

      {deletedCode && (
        <DeleteExpeditionMode
          id={deletedCode.id}
          mode={deletedCode.title}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteExpeditionMode>
      )}
      {selectedItems && (
        <DeleteExpeditionCategoryMultiple
        isLoading={loading}
          exped_mode={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteExpeditionCategoryMultiple>
      )}

{selectedElement &&(<ExpeditionCategories
selectedElement={selectedElement?.id}
openAddingDialog={openDialog}
setOpenAddingDialog={setOpenDialog}
resetSelectedItem={()=>{setSelectedElement(null)}}
></ExpeditionCategories>)
}

    </DashboardLayout>
  );
}
export default ExpeditionModes;
