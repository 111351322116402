import { RouteIdEnum } from "config/enums";
import { RouteObject } from "react-router-dom";
import NotFoundPage from "pages/NotFoundPage";
import LoginPage from "pages/Login/Login";
import PrivateRoute from "./PrivateRoute";
import Clients from "pages/Clients/Clients";
import PublicRoute from "./PublicRoute";
import PromotionCodesPage from "pages/PromotionCodes/PromotionCodes";
import CategoryPage from "pages/Category/Category";
import ProductPage from "pages/Product/Product";
import ProductAddPage from "pages/Product/ProductAdd";
import ProductEditPage from "pages/Product/ProductEdit";
import OrderPage from "pages/Order/Order";
import OpeningHoursPage from "pages/OpeningHours/OpeningHours";
import ChatPage from "pages/Chat/Chat";
import SuppliersPage from "pages/Supplier/Supplier";
import SubCategoryPage from "pages/SubCategory/SubCategory";
import DeliveryPage from "pages/Delivery/Delivery";
import ExpeditionModePage from "pages/ExpeditionMode/ExpeditionMode";
import AddExpeditonMode from "features/ExpeditionMode/ExpeditionModeAdd/AddExpeditionMode";
import TaxPage from "pages/Tax/Tax";
import EditExpeditionMode from "features/ExpeditionMode/ExpeditionModeEdit/EditExpeditionMode";
import ExpeditionCategoryPage from "pages/ExpeditionCategory/ExpeditionCategory";
import PromotionFlashesPage from "pages/PromotionFlash/PromotionFlash";
import DashboardPage from "pages/Dashboard/Dashboard";
import AddValuesExpeditionCategories from "features/AddValuesExpeditionCategories/AddValuesExpeditionCategories";
import UserDataChart from "pages/Dashboard/UserDataChart";
import BrandPage from "pages/Brand/Brand";
import DashboardStatsVisitPage from "pages/Dashboard/DashboardStatsVisits";
import Vendeurs from "UpdatedFeatures/Vendeurs/Vendeurs";
import Pos from "UpdatedFeatures/POS/Pos";

const RoutesConfig: RouteObject[] = [
  {
    path: RouteIdEnum.Root,
    element: <PrivateRoute element={<DashboardPage />} />,
  },
  {
    path: RouteIdEnum.Stats_Visit,
    element: <PrivateRoute element={<DashboardStatsVisitPage />} />,
  },
  {
    path: RouteIdEnum.Clients,
    element: <PrivateRoute element={<Clients />} />,
  },
  {
    path: RouteIdEnum.Categories,
    element: <PrivateRoute element={<CategoryPage />} />,
  },
  {
    path: RouteIdEnum.Products,
    children: [
      {
        path: RouteIdEnum.Add,
        element: <PrivateRoute element={<ProductAddPage />} />,
      },
      {
        path: RouteIdEnum.Edit + "/" + RouteIdEnum.id,
        element: <PrivateRoute element={<ProductEditPage />} />,
      },
      {
        path: RouteIdEnum.Root,
        element: <PrivateRoute element={<ProductPage />} />,
      },
    ],
  },
  {
    path: RouteIdEnum.PromotionCodes,
    element: <PrivateRoute element={<PromotionCodesPage />} />,
  },
  {
    path: RouteIdEnum.Supplier,
    element: <PrivateRoute element={<SuppliersPage />} />,
  },
  {
    path: RouteIdEnum.SubCategory,
    element: <PrivateRoute element={<SubCategoryPage />} />,
  },
  {
    path: RouteIdEnum.Expedition_Categories,
    element: <PrivateRoute element={<ExpeditionCategoryPage />} />,
  },
  {
    path: `${RouteIdEnum.Expedition_Categories}/add`,
    element: <PrivateRoute element={<AddValuesExpeditionCategories />} />,
  },
  {
    path: `${RouteIdEnum.Expedition_Mode}/add`,
    element: <PrivateRoute element={<AddExpeditonMode />} />,
  },
  {
    path: `${RouteIdEnum.Expedition_Mode}/edit/:id`,
    element: <PrivateRoute element={<EditExpeditionMode />} />,
  },
  {
    path: RouteIdEnum.Expedition_Mode,
    element: <PrivateRoute element={<ExpeditionModePage />} />,
  },
  {
    path: RouteIdEnum.Opening_Hours,
    element: <PrivateRoute element={<OpeningHoursPage />} />,
  },
  {
    path: RouteIdEnum.Chat,
    element: <PrivateRoute element={<ChatPage />} />,
  },
  {
    path: RouteIdEnum.OrderHistory,
    element: <PrivateRoute element={<OrderPage />} />,
  },
  {
    path: RouteIdEnum.Brand,
    element: <PrivateRoute element={<BrandPage />} />,
  },
  {
    path: RouteIdEnum.Promo_Flash,
    element: <PrivateRoute element={<PromotionFlashesPage />} />,
  },
  {
    path: RouteIdEnum.Login,
    element: <PublicRoute element={<LoginPage />} />,
  },
  {
    path: RouteIdEnum.Dashboard,
    element: <PrivateRoute element={<DashboardPage />} />,
  },
  {
    path: `${RouteIdEnum.Dashboard}/stat/user/:id`,
    element: <PrivateRoute element={<UserDataChart />} />,
  },
  {
    path: RouteIdEnum.Advanced_Stat,
    element: <PrivateRoute element={<DashboardPage />} />,
  },
  {
    path: RouteIdEnum.Delivery,
    element: <PrivateRoute element={<DeliveryPage />} />,
  },
  {
    path: RouteIdEnum.Tax,
    element: <PrivateRoute element={<TaxPage />} />,
  },
  {
    path: RouteIdEnum.POS, // Add route for POS
    element: <PrivateRoute element={<Pos />} />,
  },
  {
    path: RouteIdEnum.Vendeurs, // Add route for Sellers
    element: <PrivateRoute element={<Vendeurs />} />,
  },
  {
    path: RouteIdEnum.Any,
    element: <NotFoundPage />,
  },
];

export default RoutesConfig;
