import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { ISuppliersData } from "./Suppliers.types";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
} from "./Suppliers.style";
import { Delete, Edit ,RemoveRedEye} from "@mui/icons-material";
import DeleteSupplier from "../SupplierDelete/DeleteSupplier";
import { ChangeEvent, useCallback, useState } from "react";
import AddSupplier from "../SupplierAdd/AddSupplier";
import EditSupplier from "../SupplierEdit/EditSupplier";
import useSuppliersList from "./useSuppliersList";
import ShowSupplier from "../SupplierShow/SupplierShow";
import {Checkbox} from "@mui/material";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import { useDeleteMultipleSuppliersMutation } from "redux/api/suppliers/suppliers";
import { useSnackBar } from "hooks/useSnackBar";
import DeleteSupplierMultiple from "../SupplierDeleteMultiple/DeleteSupplierMultiple";

function Suppliers() {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [supplierItem, setSupplierItem] = useState<any>(
);

  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    suppliersData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
  } = useSuppliersList();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openDeleteMultipleModal,setOpenDeleteMultipleModal]=useState(false)

  const [deletedCode, setDeletedCode] = useState<{
    id: number;
    company_name: string;
  }>();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };
  const [deleteCode,{data,error,isLoading:loading,reset}] =
  useDeleteMultipleSuppliersMutation();
  const openDeleteDialogStatus = useCallback(
    (id: number, company_name: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedCode({ id: id, company_name: company_name });
    },
    [setOpenDeleteDialog, setDeletedCode]
  );
  const openDeleteMultipleDialogStatus =() => {
    setOpenDeleteMultipleModal(true);
  
};

if (error) {
  displayErrorApiSnackBar(error);
  reset();
}
if (data) {
  displaySuccessSnackBar(t("supplier.successfully_deleted"));
  reset();
}
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);

  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [openShowDialog,setOpenshowDialog]=useState<boolean>(false)
  const [checkedItems,setCheckedItems]=useState<number[]>([]);

  const [editedCodeInfo, setEditedCodeInfoCode] = useState<{
    id: number,
    company_name:string,
    address: string,
    postal_code: string,
    city: string,
    region:string,
    department:string,
    country:string,
    email:string ,
    office_phone:string,
    mobile_phone:string,
    website:string,
    contact_last_name:string,
    contact_first_name:string,
    contact_position:string,
    contact_mobile:string,
    contact_email:string,
    contact_office_phone:string

  }>();
  const handleChangeCheckbox=(event:React.ChangeEvent<HTMLInputElement>,id:number)=>{
    if(event.target.checked){
    setCheckedItems((prevState:number[])=>([...prevState,id]))
    }
    else {
      setCheckedItems((prevState:number[])=>(prevState.filter((item:number)=>(item!==id))))

    }
  }
  const [supplier, setSupplier] = useState<{
    id: number,
    company_name:string,
    address: string,
    postal_code: string,
    city: string,
    region:string,
    department:string,
    country:string,
    email:string ,
    office_phone:string,
    mobile_phone:string,
    website:string,
    contact_last_name:string,
    contact_first_name:string,
    contact_position:string,
    contact_mobile:string,
    contact_email:string,
    contact_office_phone:string

  }>();


  const onChange:any = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSupplierItem({ ...supplierItem, [field]: e.target.value });

      },
    [supplierItem]
  );
  const openEditingDialogStatus = useCallback(
    (
      id: number,
      company_name:string,
      address: string,
      postal_code: string,
      city: string,
      region:string,
      department:string,
      country:string,
      email:string ,
      office_phone:string,
      mobile_phone:string,
      website:string,
      contact_last_name:string,
        contact_first_name:string,
        contact_position:string,
        contact_mobile:string,
        contact_email:string,
        contact_office_phone:string
      ) =>
      () => {
        setEditedCodeInfoCode({ id ,company_name,
          address ,
          postal_code,
          city,
          region,
          department,
          country,
          email ,
          office_phone,
          mobile_phone,
          website,
          contact_last_name,
          contact_first_name,
          contact_position,
          contact_mobile,
          contact_email,
          contact_office_phone});
        setOpenEditingDialog(true);
      },
    []
  );
  const openShowDialogStatus =(id: number,
    company_name:string,
    address: string,
    postal_code: string,
    city: string,
    region:string,
    department:string,
    country:string,
    email:string ,
    office_phone:string,
    mobile_phone:string,
    website:string,
    contact_last_name:string,
      contact_first_name:string,
      contact_position:string,
      contact_mobile:string,
      contact_email:string,
      contact_office_phone:string
    )=>{
        setOpenshowDialog(true);
        setSupplier({ id ,company_name,
          address ,
          postal_code,
          city,
          region,
          department,
          country,
          email ,
          office_phone,
          mobile_phone,
          website,
          contact_last_name,
          contact_first_name,
          contact_position,
          contact_mobile,
          contact_email,
          contact_office_phone})
      }

  const closeShowDialog = useCallback(() => {
    setOpenshowDialog(false);
    setSupplierItem({   id: 0,
      company_name:"",
      address: "",
      postal_code: "",
      city: "",
      region: "",
      department:"",
      country:"",
      email:"" ,
      office_phone:"",
      mobile_phone:"",
      website:"",
      contact_last_name:"",
        contact_first_name:"",
        contact_position:"",
        contact_mobile:"",
        contact_email:"",
        contact_office_phone:""
    });
  }, [openShowDialog]);
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const deleteMultiple=(event:any)=>{
    event.preventDefault()
   deleteCode({
      providers_ids:selectedItems,
      select_all:selectAll,
      search:search
  })
  if(!error)
  setOpenDeleteMultipleModal(false)
reset()
  }
  return (
    <DashboardLayout
      title={t("menu.supplier")}
      selectedPath={RouteIdEnum.Supplier}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <ButtonContainerStyle item xs={12} sm={4}>
        {((selectAll || selectedItems.length != 0) )&&<DeleteButton onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
       }
         &nbsp;
          <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </ButtonContainerStyle>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: <Checkbox  color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "company_name",
            title: t("supplier.company_name"),

            onClick: onOrderChange("company_name"),
            asc: orderBy === "company_name" ? orderDir === "asc" : undefined,
          },
          {
              key: "address",
            title: t("supplier.address"),
            onClick: onOrderChange("address"),
            asc: orderBy === "address" ? orderDir === "asc" : undefined,
          },
          {
            key: "postal_code",
            title: t("supplier.postal_code"),
            onClick: onOrderChange("postal_code"),
            asc: orderBy === "postal_code" ? orderDir === "asc" : undefined,
          },
          {
            key: "city",
            title: t("supplier.city"),
            onClick: onOrderChange("city"),
            asc: orderBy === "city" ? orderDir === "asc" : undefined,
          },
          {
            key: "region",
            title: t("supplier.region"),
            onClick: onOrderChange("region"),
            asc: orderBy === "region" ? orderDir === "asc" : undefined,
          },
          {
            key: "department",
            title: t("supplier.department"),
            onClick: onOrderChange("department"),
            asc: orderBy === "department" ? orderDir === "asc" : undefined,
          },
          {
            key: "country",
            title: t("supplier.country"),
            onClick: onOrderChange("country"),
            asc: orderBy === "country" ? orderDir === "asc" : undefined,
          },
          {
            key: "email",
            title: t("supplier.email"),
            onClick: onOrderChange("email"),
            asc: orderBy === "email" ? orderDir === "asc" : undefined,
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={suppliersData?.data.map((e: ISuppliersData) => {
          console.log("record",e.department)
          return {
            checkbox: (
              <Checkbox  checked={(!selectAll && selectedItems.includes(e.id))||(selectAll && !selectedItems.includes(e.id))}
              onChange={() => handleCheckboxChange(e.id)}
              />

            ),            
            company_name: <p>{e.company_name}</p>,
            address: <p>{e.address}</p>,
            postal_code: <p>{e.postal_code}</p>,
            city: <p>{e.city}</p>,
            region: <p>{e.region}</p>,
            department: <p>{e.department}</p>,
            country: <p>{e.country}</p>,
            email: <p>{e.email}</p>,
        


            action: (
              <ActionContainerStyle>
                   {
                  <Tooltip title={t("common.show")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={()=>openShowDialogStatus(
                        e.id,
                        e.company_name,
                        e.address,
                        e.postal_code,
                        e.city,
                        e.region,
                        e.department,
                        e.country,
                        e.email,
                        e.office_phone,
                        e.mobile_phone,
                        e.website,
                        e.contact_last_name,
                        e.contact_first_name,
                        e.contact_position,
                        e.contact_mobile,
                        e.contact_email,
                        e.contact_office_phone
                      )}
                    >
                      <RemoveRedEye />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.show")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openEditingDialogStatus(
                        e.id,
                        e.company_name,
                        e.address,
                        e.postal_code,
                        e.city,
                        e.region,
                        e.department,
                        e.country,
                        e.email,
                        e.office_phone,
                        e.mobile_phone,
                        e.website,
                        e.contact_last_name,
                        e.contact_first_name,
                        e.contact_position,
                        e.contact_mobile,
                        e.contact_email,
                        e.contact_office_phone

                      )}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.company_name)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: suppliersData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />

      {deletedCode && (
        <DeleteSupplier
          id={deletedCode.id}
          company_name={deletedCode.company_name}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteSupplier>
      )}
      <AddSupplier
        openAddingDialog={openAddingDialog}
        setOpenAddingDialog={setOpenAddingDialog}
      ></AddSupplier>
      {editedCodeInfo && (
        <EditSupplier
          id={editedCodeInfo.id}
          codeInfos={{
            company_name: editedCodeInfo.company_name,
            address: editedCodeInfo.address,
            postal_code: editedCodeInfo.postal_code,
            city: editedCodeInfo.city,
            region: editedCodeInfo.region,
            department:editedCodeInfo.department,
            country:editedCodeInfo.country,
            email:editedCodeInfo.email,
            office_phone:editedCodeInfo.office_phone,
            mobile_phone:editedCodeInfo.mobile_phone,
            website:editedCodeInfo.website,
            contact_last_name:editedCodeInfo.contact_last_name,
            contact_first_name:editedCodeInfo.contact_first_name,
            contact_position:editedCodeInfo.contact_position,
            contact_mobile:editedCodeInfo.contact_mobile,
            contact_email:editedCodeInfo.contact_email,
            contact_office_phone:editedCodeInfo.contact_office_phone

          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditSupplier>
      )}
      {
           supplier && (
            <ShowSupplier
              id={supplier.id}
              codeInfos={{
                company_name: supplier.company_name,
                address: supplier.address,
                postal_code: supplier.postal_code,
                city: supplier.city,
                region: supplier.region,
                department:supplier.department,
                country:supplier.country,
                email:supplier.email,
                office_phone:supplier.office_phone,
                mobile_phone:supplier.mobile_phone,
                website:supplier.website,
                contact_last_name:supplier.contact_last_name,
                contact_first_name:supplier.contact_first_name,
                contact_position:supplier.contact_position,
                contact_mobile:supplier.contact_mobile,
                contact_email:supplier.contact_email,
                contact_office_phone:supplier.contact_office_phone

              }}
              openShowDialog={openShowDialog}
              setOpenShowDialog={setOpenshowDialog}
            ></ShowSupplier>
          )}
           {selectedItems && (
        <DeleteSupplierMultiple
        isLoading={loading}
          supplier_ids={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteSupplierMultiple>
      )}

    </DashboardLayout>
  );
}
export default Suppliers;
