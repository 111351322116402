import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IOrderHistoryData, IProductOrderData, IUserOrderData } from "./Order.types";
import { Grid,IconButton ,MenuList,MenuItem, ListItemText} from "@mui/material";
import moment from "moment";
import SearchInput from "components/common/SearchInput/SearchInput";
import useOrderList from "./useOrderList";
import SelectInput from "components/common/SelectInput/SelectInput";
import { useEffect, useState } from "react";
import ShowClient from "features/Client/ShowClient/ShowClient";
import { useUpdateOrderStatusMutation } from "redux/api/order/order";
import { useSnackBar } from "hooks/useSnackBar";
import {  LiIconExpandLessStyle,LiIconExpandMoreStyle} from "components/Dashboard/Menu/Menu.style"
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import React from "react";
import MenuItems from "components/common/Menu/Menu";
function Order() {
  const { t } = useTranslation();
  const [selectedRecord,setSelectedRecord]=useState<IOrderHistoryData>()
  const [status,setStatus]=useState("")
  const styleStatus=(color:string):React.CSSProperties=>({
    display: 'inline-flex',
    padding: '0.5rem', // Adjust as needed
    fontSize: '0.75rem', // Adjust as needed
    fontWeight: '500', // Adjust as needed
    lineHeight: '1.25', // Adjust as needed
    borderRadius: '9999px', // To ensure rounded shape
    color: "white", // Adjust as needed
    backgroundColor: color, // Adjust as needed
    textAlign:"center"
  })
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    orderData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
  } = useOrderList();
  const [showModalClient,setShowModalClient]=useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setShow(!show)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShow(!show)
  };
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [show,setShow]=useState(false)
  const [updateStatus,{data,error,isLoading:loading,reset}]=useUpdateOrderStatusMutation()
  /*useEffect(()=>{
    const arr=[];
    for(var i=0;i<orderData?.data.length;i++)
      arr.push(0);
   setStatus(arr);
  },[orderData,status])
  useEffect(()=>{
    console.log("status",status)
  },[orderData,status])*/
  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("order.successfully_changed"));
    reset();
  }
  const onChange=(e:string,id:number)=>{
    updateStatus({order_id:id,status:e})
    setAnchorEl(null)
  }
    const handleShowStatusItems=(e:IOrderHistoryData)=>{
    setSelectedRecord(e)

   }
  return (
    <DashboardLayout
      title={t("order.page_title")}
      selectedPath={RouteIdEnum.OrderHistory}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
      </Grid>
      {
        showModalClient&& selectedRecord&&(<ShowClient
          id={selectedRecord?.user?.id}


        openShowDialog={showModalClient} setOpenShowDialog={setShowModalClient} />)

      }
      <Table
        loader={isLoading}
        headerData={[
          {
            key: "id",
            title: t("order.id"),
          },
          {
            key: "name",
            title: t("order.name"),
          },
          {
            key: "products",
            title: t("order.products"),
          },
          {
            key: "expedition_mode",
            title: t("order.mode"),
          },
          {
            key: "total",
            title: t("order.total"),
          },
          {
            key: "status",
            title: t("order.status"),
          },

          {
            key: "promotion_code",
            title: t("order.promotion_code"),
          },
          {
            key: "created_at",
            title: t("common.created_at"),
            onClick: onOrderChange("created_at"),
            asc: orderBy === "created_at" ? orderDir === "asc" : undefined,
          },
          {
            key: "actions",
            title: "Actions",
          },
        ]}
        bodyData={orderData?.data?.map((e: IOrderHistoryData,index:number) => {
          console.log("record",e)
          return {
            id:<p>{`Order-${e.id}`}</p>,
            name: <a style={{textDecoration:"underline",cursor:"pointer"}} onClick={(event:any)=>{setShowModalClient(true);setSelectedRecord(e);}} >{e?.user?.first_name + " " + e?.user?.last_name}</a>,
            products: <ul style={{overflowY:"auto"}}>{Object.values(e.products).map((product:IProductOrderData)=>(
                   <li>{`${product?.quantity}x ${product.product_title}`}</li>))
            }

            </ul>,
            expedition_mode:<p>{e.shipping_mode_id}</p>,
            total:<p>{e.total_ttc}</p>,
            status:e.status=="0"?<p style={styleStatus("red")}>Annulé</p>:e.status=="1"?<p style={styleStatus("orange")}>Payement en attente</p>:e.status=="2"?<p style={styleStatus("blue")}>En cours de traitement</p>:
            e.status=="3"?<p style={styleStatus("orange")}>En cours de livraison</p>:e.status=="4"?<p style={styleStatus("green")}>Livré</p>:e.status=="5"?<p style={styleStatus("red")}>Echec de paiement</p>:null,
            promotion_code:<p>{e.promotion_code_id}</p>,

            //promotion_code: <p>{e.promotion_code?.code}</p>,
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            actions:    (  /* <React.Fragment> 
               <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
               <Tooltip title="Status">
               <IconButton
 onClick={(event:any)=>{handleShowStatusItems(e);handleClick(event)}}          
   size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
                <Settings 
 />  
 </IconButton>
 </Tooltip>
 </Box>
          <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={anchorEl &&Boolean(anchorEl) && selectedRecord && e.id === selectedRecord.id}           
       onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
         
       
          >
          {open &&selectedRecord&&e.id===selectedRecord.id &&[{ id: 0, name: "Annulé" },{ id: 1, name: "Paiement en attente" }, { id: 2, name: "En cours de traitement" }, { id: 3, name: "En cours de livraison" }, { id: 4, name: "Livré" }].map((item:any)=>(
               <MenuItem key={item.id} onClick={()=>onChange(item.id,selectedRecord.id)}>
                 {item.name}
                  </MenuItem>
        ))}
         
          </Menu></React.Fragment> */
          <MenuItems 
          list={[{ id: 0, name: "Annulé" },{ id: 1, name: "Paiement en attente" }, { id: 2, name: "En cours de traitement" }, { id: 3, name: "En cours de livraison" }, { id: 4, name: "Livré" }]}
            currentOrder={e}
            selectedRecord={selectedRecord}
            onChange={onChange}
            handleClick={(event:any)=>{handleShowStatusItems(e);handleClick(event)}}
            anchorEl={anchorEl}
            open={anchorEl &&Boolean(anchorEl) && selectedRecord && e.id === selectedRecord.id}
          />)
          
        }})}
        paginationData={{
          page: page,
          lastPage: orderData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />



    </DashboardLayout>
  );
}
export default Order;
