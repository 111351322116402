import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IClientData } from "./Client.types";
import { Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import SearchInput from "components/common/SearchInput/SearchInput";
import useClientList from "./useClientList";
import StatusBadge from "components/common/StatusBadge/StatusBadge";
import { ActionContainerStyle } from "./Client.style";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useState } from "react";

function Client() {
  const { t } = useTranslation();

  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    clientData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    updateClientStatus,
  } = useClientList();

  return (
    <DashboardLayout
      title={t("client.page_title")}
      selectedPath={RouteIdEnum.Clients}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "first_name",
            title: t("client.first_name"),
            onClick: onOrderChange("first_name"),
            asc: orderBy === "first_name" ? orderDir === "asc" : undefined,
          },
          {
            key: "last_name",
            title: t("client.last_name"),
            onClick: onOrderChange("last_name"),
            asc: orderBy === "last_name" ? orderDir === "asc" : undefined,
          },
          {
            key: "email",
            title: t("client.email"),
            onClick: onOrderChange("email"),
            asc: orderBy === "email" ? orderDir === "asc" : undefined,
          },
          {
            key: "phone",
            title: t("client.phone"),
          },
          {
            key: "delivery_address",
            title: t("client.delivery_address"),
          },
          {
            key: "payment_expiration",
            title: t("client.payment_expiration"),
          },
          {
            key: "status",
            title: t("client.status"),
          },
          {
            key: "created_at",
            title: t("common.created_at"),
            onClick: onOrderChange("created_at"),
            asc: orderBy === "created_at" ? orderDir === "asc" : undefined,
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={clientData?.data.map((e: IClientData) => {
          return {
            first_name: <p>{e.first_name}</p>,
            last_name: <p>{e.last_name}</p>,
            email: <p>{e.email}</p>,
            phone: <p>{e.phone}</p>,
            delivery_address: <p>{e.delivery_address}</p>,
            payment_expiration: (
              <div>
                {moment.unix(e.payment_expiration).format("DD/MM/YYYY")}
              </div>
            ),
            status: <StatusBadge isEnabled={e.status ? true : false} />,
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip
                    title={
                      e.is_enable ? t("client.disable") : t("client.enable")
                    }
                  >
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={updateClientStatus(e.id)}
                    >
                      {e.is_enable ? <LockOpenIcon /> : <LockIcon />}
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: clientData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
    </DashboardLayout>
  );
}
export default Client;
