import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "config/constant";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { IPromotionCodesAddingData, IPromotionCodesAddingDataApi } from "features/PromotionCodes/PromotionCodeAdd/AddPromotionCode.types";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const promotionCodesApi = createApi({
  reducerPath: "PromotionCodesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Codes"],
  endpoints: (builder) => ({
    getPromotionCodesById: builder.query({
      query: (id: string) => ENDPOINTS.PROMOTION_CODES + id,
    }),
    getAllPromotionCodes: builder.query({
      query: (params: { paginator?: Paginator }) => {
        return injectParamsPaginator(
          ENDPOINTS.PROMOTION_CODES,
          params.paginator
        );
      },
      providesTags: ["Codes"],
    }),
    getAllPromotionCodesWithoutPagination: builder.query({
      query: (params: { paginator?: Paginator }) => {
        return injectParamsPaginator(
          `${ENDPOINTS.PROMOTION_CODES}/all`,
          params.paginator
        );
      },
      providesTags: ["Codes"],
    }),
    deletePromotionCode: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.PROMOTION_CODES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Codes"],
    }),
    addPromotionCode: builder.mutation({
      query: ({
        code,
        start_date,
        end_date,
        link,
    categories,
    subCategories,
    products,
    isLimitedusers,
    isLinkedUsers,
    isUserExist,
    isUserNotExist,
    linkcodesubscription,
    subscriptions,
    isLinkedPromoSubscription,
    isDiscountMin,
    isUnlimitedUsers,
    nbUser,
    discount,
    email,
    percentage_value,
    eur_value,
    client,
    type

      }: IPromotionCodesAddingDataApi) => ({
        url: `${ENDPOINTS.PROMOTION_CODES}`,
        method: "POST",
        body: { code, start_date, end_date,    link,
        categories,
        subCategories,
        products,
        isLimitedusers,
        isLinkedUsers,
        isUserExist,
        isUserNotExist,
        linkcodesubscription,
        subscriptions,
        isLinkedPromoSubscription,
        isDiscountMin,
        isUnlimitedUsers,
        nbUser,
        discount,
        email,
        percentage_value,
        eur_value,
        client ,type},
      }),
      invalidatesTags: ["Codes"],
    }),
    deleteMultiplePromotionCodes: builder.mutation({
      query: (params: { codes_ids: number[], select_all: boolean ,search:string}) => ({
        url: `${ENDPOINTS.PROMOTION_CODES}/delete-multiple`,
        method: "POST",
        body: {
          codes_ids: params.codes_ids,
          select_all: params.select_all,
          search:params.search
        },
      }),
      invalidatesTags: ["Codes"],
    }),
    updatePromotionCode: builder.mutation({
      query: ({
    id,
    code,
    start_date,
    end_date,
    link,
    categories,
    subCategories,
    products,
    isLimitedusers,
    isLinkedUsers,
    isUserExist,
    isUserNotExist,
    linkcodesubscription,
    subscriptions,
    isLinkedPromoSubscription,
    isDiscountMin,
    isUnlimitedUsers,
    nbUser,
    discount,
    email,
    percentage_value,
    eur_value,
    client,
    type
      }: IPromotionCodesAddingDataApi) => ({
        url: `${ENDPOINTS.PROMOTION_CODES}/${id}`,
        method: "POST",
        body: {
          code,
          start_date,
          end_date,
          link,
          categories,
          subCategories,
          products,
          isLimitedusers,
          isLinkedUsers,
          isUserExist,
          isUserNotExist,
          linkcodesubscription,
          subscriptions,
          isLinkedPromoSubscription,
          isDiscountMin,
          isUnlimitedUsers,
          nbUser,
          discount,
          email,
          percentage_value,
          eur_value,
          client,
        type },
      }),
      invalidatesTags: ["Codes"],
    }),
  }),
});

export const {
  useGetAllPromotionCodesQuery,
  useGetPromotionCodesByIdQuery,
  useDeletePromotionCodeMutation,
  useAddPromotionCodeMutation,
  useUpdatePromotionCodeMutation,
  useDeleteMultiplePromotionCodesMutation,
  useGetAllPromotionCodesWithoutPaginationQuery
} = promotionCodesApi;
