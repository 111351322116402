import InputsContainer from "components/common/InputsContainer/InputsContainer";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AddSupplier from "features/Supplier/SupplierAdd/AddSupplier";
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';

import {
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItemBar,
  Switch,
  Tooltip,
} from "@mui/material";
import {
  AutoInputsContainerStyle,
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageGalleryStyle,
  ImageListItemStyle,
  ImageStyle,
  InputStyle,
} from "./Product.style";
import TextInput from "components/common/TextInput/TextInput";
import SelectInput from "components/common/SelectInput/SelectInput";
import { ICategoryData } from "features/Category/CategoryList/Category.types";
import FileInput from "components/common/FileInput/FileInput";
import Button from "components/common/Button/Button";
import Dialog from "components/common/Dialog/Dialog";
import { GlobalVariables } from "config/constant";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import useAddProductList from "./useAddProductList";
import Table from "components/common/Table/Table";
import { useNavigate } from "react-router-dom";
import { ActionContainerStyle, ButtonContainerStyle } from "../ProductList/Product.style";
import AddButton from "components/common/AddButton/AddButton";
import { ChangeEvent, useCallback, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import DeleteNutriValue from "features/NutriValue/NutriValueDelete/DeleteNutriValue";
import useTaxList from "features/Taxe/TaxeList/useTaxList";
import useSuppliersList from "features/Supplier/SuppliersList/useSuppliersList";
import { ISuppliersData } from "features/Supplier/SuppliersList/Suppliers.types";
import SelectInputNutriScore from "components/common/SelectInput/SelectInputNutriScore";
import SelectInputShow from "components/common/SelectInput/SelectInputShow";
import useExpeditionCategoriesList from "features/ExpeditionCategory/ExpeditionCategList/useExpeditionCategoriesList";
import AddTax from "features/Taxe/TaxAdd/AddTax";
import AutoComplete from "components/common/AutoComplete/AutoComplete";
import AutoCompleteMultipleInput from "components/common/AutoCompleteInputMultiple/AutoCompleteMultipleInput";
import AddBrand from "features/Brand/BrandAdd/AddBrand";
import useBrandList from "features/Brand/BrandList/useBrandList";
import { IBrandData } from "features/Brand/BrandList/Brand.types";
function ProductAdd() {
  const { t } = useTranslation();
  const [showNutri, setShowNutri] = useState(false)
  const [showModalAddProvider, setShowModalAddProvider] = useState<boolean>(false)
  const [showModalAddBrand, setShowModalAddBrand] = useState<boolean>(false)
  const openAddingProviderDialogStatus = useCallback(() => {
    setShowModalAddProvider(true);
  }, []);
  const openAddingBrandDialogStatus = useCallback(() => {
    setShowModalAddBrand(true);
  }, []);
  const [taxValue, setTaxValue] = useState<{ id: number, name: string, percentage: string } | null>(null)
  const navigate = useNavigate()
  const [openModalTax, setOpenModalTax] = useState(false);
  const {
    categoryData,
    addingData,
    onChange,
    onChangeSelectCategory,
    openAddingDialogContainer,
    addingImage,
    addingError,
    addProductForm,
    openAddingDialog,
    closeAddingDialogContainer,
    onSuccessAddingDialog,
    addingOneImage,
    onChangeOneImage,
    addingOneImageError,
    isMainImageSelected,
    onChangeSelectNutriscore,
    subcategories,
    onChangeSelectSubCategory,
    setAddingData,
    onChangeSelectTax,
    onChangeSelectProvider,
    onChangeSelectBrand,
    onChangeSelectShow,
    onChangeSelectCategExped,
    onChangeKeyWords,
    setAddingError,
    onChangeSelectStatus,
    handleDeleteImage
  } = useAddProductList();
  const [nutrivalue, setNutriValue] = useState({
    id: 0,
    name: "",
    value: "",
  })

  interface Option {
    id: number;
    name: string;
  }

  const options: Option[] = [
    { id: 1, name: 'Option 1' },
    { id: 2, name: 'Option 2' },
    { id: 3, name: 'Option 3' },
  ];
  const { taxesData } = useTaxList()



  const { brandData } = useBrandList()
  const { suppliersData } = useSuppliersList()

  const { catgorievalues } = useExpeditionCategoriesList()
  const [taxes, setTaxes] = useState(taxesData);

  const [inputKeyWord, setInputKeyWord] = useState<string>("")
  const onChangeNutriValue = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNutriValue({ ...nutrivalue, [field]: e.target.value });
        if (field == "value") {

          setNutriValue({ ...nutrivalue, value: e.target.value })

        }
      },
    [nutrivalue]
  );


  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault()
    /*editingTax({
      id: editingData.id,
      name:editingData.name,
      value:editingData.value,
    });*/
    setOpenEditNutriValueDialog(false)
    setAddingData((prevItems: any) => {
      if (prevItems.nutrivalues.length > 0) {
        const newArray: any = addingData.nutrivalues.map((item) =>
          editedNutiValue.id === item.id ? { id: item.id, name: editedNutiValue.name, value: editedNutiValue.value } : item
        );
        return {
          ...prevItems,
          nutrivalues: newArray

        };
      }
    })

  }
  const handleDelete = (item: string) => {
    setAddingData((prevItems: any) => {
      if (prevItems.keyWords.length > 0) {
        const updatedKeyWords = prevItems.keyWords.filter((itemKey: string, index: number) => itemKey !== item)
        return {
          ...prevItems,
          keyWords: updatedKeyWords

        };
      }
    })



  }
  const updateNutriValuesAfterDelete = (name: string) => {
    if (addingData.nutrivalues) {
      const nutrivalues: any = addingData.nutrivalues.filter((nutrival) => nutrival.name !== name)
      setAddingData((addingDataPrev: any) => ({
        ...addingDataPrev,
        nutrivalues: nutrivalues
      }));
    }
  }

  const [counter, setCounter] = useState(1)
  const [openEditNutriValueDialog, setOpenEditNutriValueDialog] = useState<boolean>(false);
  const [openDeleteNutriValueDialog, setOpenDeleteNutriValueDialog] = useState<boolean>(false);
  const [hasError, sethasError] = useState<boolean>(false);
  const [deletedNutiValue, setDeletedNutiValue] = useState<{
    name: string;
  }>();
  const [editedNutiValue, setEditedNutiValue] = useState<any>();
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
      setAddingData((prevState) => ({
        ...prevState,
        keyWords: [...prevState.keyWords, inputKeyWord], // Adding new element to the array
      }));
  };

  const openDeleteNutriValueDialogStatus = useCallback(
    (name: string) => () => {
      setOpenDeleteNutriValueDialog(true);
      setDeletedNutiValue({ name: name });
    },
    [setOpenDeleteNutriValueDialog, setDeletedNutiValue]
  );

  useEffect(() => {
    const Error = Object.values(addingError).some(value => {
      return Array.isArray(value) ? value.length != 0 : value != "";
    });
    sethasError(Error);
  }, [addingError]);
  useEffect(() => {
    console.log("addingImageOne", addingImage)
  }, [addingImage])
  const openEditNutriValueDialogStatus = useCallback(
    (id: number, name: string, value: string) => () => {
      console.log("id", id, "name", name, "value", value)
      setOpenEditNutriValueDialog(true);
      setEditedNutiValue({ id: id, name: name, value: value });
    },
    [setOpenEditNutriValueDialog, setEditedNutiValue]
  );

  const onChangeEditedNutriValue = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditedNutiValue({ ...editedNutiValue, [field]: e.target.value });

      },
    [editedNutiValue]
  );
  const handleInputChange = (event: any, newValue: any) => {
    setAddingData((prevState) => ({
      ...prevState,
      tax_id: newValue, // Adding new element to the array
    }));
    setAddingError((prevState) => ({
      ...prevState,
      tax_id: "" // Adding new element to the array
    }));
  }

  return (
    <>
      <DashboardLayout
        title={t("product.add")}
        selectedPath={RouteIdEnum.Products}
        backPath={RouteIdEnum.Products}
      >

        <InputsContainer title={t("product.info_add")}>
          <Grid container spacing={1}>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
              required
                value={addingData.title}
                label={t("product.title")}
                placeholder={t("product.title")}
                onChange={(onChange("title"))}
                error={t(addingError.title)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <SelectInput
                label={t("product.status")}
                error={addingError.status}
                value={addingData.status}
                onChange={onChangeSelectStatus("status")}



                data={[{ id: 1, title: "Brouillon" }, { id: 2, title: "Publié" }, { id: 3, title: "Différé" }].map((e: any) => ({
                  id: e.id,
                  name: e.title,
                }))}
              />

            </AutoInputsContainerStyle>
            {addingData.status == "3" && <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                value={`${addingData.publication_date}`}
                label={t("product.publication_date")}
                placeholder={t("product.publication_date")}
                type="date"
                onChange={onChange("publication_date")}
                error={t(addingError.publication_date)}
              />
            </AutoInputsContainerStyle>
            }
            <br />
            <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
              <TextInput
              required={addingData.status=="1"?false:true}
                value={addingData.description}
                multiline={true}
                label={t("product.description")}
                placeholder={t("product.description")}
                onChange={onChange("description")}
                error={t(addingError.description)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
              required={addingData.status=="1"?false:true}
                value={addingData.HT_price}
                label={t("product.HTprice")}
                placeholder={t("product.HTprice")}
                type="number"
                onChange={onChange("HT_price")}
                error={t(addingError.HT_price)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
              required={addingData.status=="1"?false:true}
                value={addingData.quantity}
                label={t("product.quantity")}
                placeholder={t("product.quantity")}
                type="number"
                onChange={onChange("quantity")}
                error={t(addingError.quantity)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
              required={addingData.status=="1"?false:true}
                value={addingData.price}
                label={t("product.price")}
                type="number"
                placeholder={t("product.price")}
                onChange={onChange("price")}
                error={t(addingError.price)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
             
              {taxesData && <AutoComplete
              required={addingData.status=="1"?false:true}
                label=""
                data={taxesData?.data?.map((e: any) => ({
                  id: e.id,
                  name: e.name,
                  percentage: e.percentage
                }))}
                getOptionLabel={(option: any) => { return option ? option.name : "Taxe" }}
                value={addingData.tax_id ?? { id: 0, name: "", percentage: "" }}
                onChange={(event: any, newValue: any) => {
                  console.log("taxval", taxValue)

                  if (newValue === "") {
                    setOpenModalTax(true)

                  }
                  console.log("newValue", newValue)
                  handleInputChange(event, newValue)

                }}
                noOptionsText={
                  <a style={{ cursor: "pointer", textDecoration: "underline", backgroundColor: "#631958bd" }} onClick={() => { setOpenModalTax(true) }}>Ajouter un nouveau taxe</a>

                }
                renderInput={(params) => (
                  <TextField
                  required={addingData.status=="1"?false:true}
                  sx={{
                    backgroundColor: "#fff",
                    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
                      backgroundColor: "#fbf9f9",
                      margin:0,
                      fontSize: 14,
                      
                    },
                 
                }} {...params} error={addingError.tax_id} helperText={addingError.tax_id} label="Taxe" />
                )}
              />
              }


            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                disabled={true}
                value={`${(parseFloat(addingData.price) + ((parseFloat(addingData.price) * parseFloat(addingData.tax_id?.percentage ?? "0")) / 100)).toFixed(2)}`}

                label={t("product.TTC_price")}
                placeholder={t("product.TTC_price")}
                type="number"
                onChange={onChange("TTC_price")}
                error={t(addingError.TTC_price)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                value={addingData.format}
                label={t("product.product_format")}
                placeholder={t("product.product_format")}
                onChange={onChange("format")}
                error={t(addingError.format)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                value={`${addingData.bar_code}`}
                label={t("product.bar_code")}
                placeholder={t("product.bar_code")}
                onChange={onChange("bar_code")}
                error={t(addingError.bar_code)}
                required={addingData.status=="1"?false:true}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                value={`${addingData.credits}`}
                label={t("product.credits")}
                type="number"
                placeholder={t("product.credits")}
                onChange={onChange("credits")}
                error={t(addingError.credits)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                value={`${addingData.tag}`}
                label={t("product.tag")}
                placeholder={t("product.tag")}
                onChange={onChange("tag")}
                error={t(addingError.tag)}
              />
            </AutoInputsContainerStyle>


            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <SelectInputShow
              required={addingData.status=="1"?false:true}
                none={true}
                label={t("product.show")}
                error={addingError.show[0]}
                value={addingData.show ?? []}
                onChange={onChangeSelectShow("show")}
                data={[{ id: 1, title: "Application" }, { id: 2, title: "Boutique " }, { id: 3, title: "Point de vente " }].map((e: any) => ({
                  id: e.id,
                  name: e.title,
                }))}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                type="number"
                value={`${addingData.limit_stock}`}
                label={t("product.limit_stock")}
                placeholder={t("product.limit_stock")}
                onChange={onChange("limit_stock")}
                error={t(addingError.limit_stock)}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                value={addingData.min_quantity}
                type="number"
                label={t("product.min_quantity")}
                placeholder={t("product.min_quantity")}
                onChange={onChange("min_quantity")}
                error={`${t(addingError.min_quantity)}`}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              {categoryData && (
                <SelectInputNutriScore
                  none={true}
                  label={t("product.nutriscore")}
                  error={addingError.nutriscore}
                  value={addingData.nutriscore ?? ""}
                  onChange={onChangeSelectNutriscore("nutriscore")}
                  data={[{ id: 1, title: "A", color: "#037d3f" }, { id: 2, title: "B", color: "#81b52e" }, { id: 3, title: "C", color: "#f6c502" }, { id: 4, title: "D", color: "#e77d00" }, { id: 5, title: "E", color: "#df3c10" }]?.map((e: any) => ({
                    id: e.id,
                    name: e.title,
                    color: e.color,
                  }))}
                />
              )}
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              {catgorievalues && <AutoComplete
              required={addingData.status=="1"?false:true}
                label="Catégorie d'expédition"
                value={addingData?.categ_exped ?? null}
                error={t(addingError.categ_exped)}
                onChange={onChangeSelectCategExped}
                data={catgorievalues?.data?.map((e: any) => ({
                  id: e.id,
                  label: e.name,
                }))}
                renderInput={(params: any) => (
                  <TextField
                  required={addingData.status=="1"?false:true}
                    {...params}
                    error={addingError.categ_exped} helperText={addingError.categ_exped}
                    label="Catégories d'expédition"
                    placeholder="Catégories d'expédition"
                    sx={{
                        backgroundColor: "#fff",
                        "&  .MuiFormHelperText-root.Mui-error": { //<--- here
                          backgroundColor: "#fbf9f9",
                          margin:0,
                          fontSize: 14,
                          
                        },
                     
                    }}
                  />
                )}
              />}

              
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              {<AutoCompleteMultipleInput
                multiple
                value={addingData.keyWords}
                options={addingData.keyWords}
                freeSolo
                onInputChange={(event: any, newValue: string) => setInputKeyWord(newValue)}
                renderTags={(value: string[], getTagProps: any) => {
                  return value.map((option: string, index: number) => {
                    console.log("option", option)
                    return <Chip
                      {...getTagProps({ index })}
                      label={option} onDelete={handleDelete.bind(null, option)}
                    />
                  })
                }}


                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Mots Clés"
                    onKeyDown={handleKeyDown}
                    placeholder="Mots clés"
                  />
                )}
              />
              }
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                required={addingData.status=="1"?false:true}
                value={`${addingData.marge}`}
                label={t("product.marge")}
                type="number"
                placeholder={t("product.marge")}
                onChange={onChange("marge")}
                error={t(addingError.marge)}
                
              />
            </AutoInputsContainerStyle>
          </Grid>
        </InputsContainer>
        <InputsContainer title={t("product.category")}>
          <Grid container spacing={1} style={{ justifyContent: "center" }}>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              {categoryData && (
                <SelectInput
                  required={addingData.status=="1"?false:true}
                  none={true}
                  label={t("product.category")}
                  error={addingError.category_product_id}
                  value={addingData.category_product_id ?? ""}
                  onChange={onChangeSelectCategory("category_product_id")}
                  data={categoryData.data?.map((e: ICategoryData) => ({
                    id: e.id,
                    name: e.title,
                  }))}
                />
              )}
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              {subcategories && (
                <SelectInput
                  required={addingData.status=="1"?false:true}
                  none={true}
                  label={t("product.subcategory")}
                  error={addingError.subCategory_product_id ?? ""}
                  value={addingData.subCategory_product_id ?? ""}
                  onChange={onChangeSelectSubCategory("subCategory_product_id")}
                  data={subcategories.data?.map((e: ICategoryData) => ({
                    id: e.id,
                    name: e.title,
                  }))}
                />
              )}
            </AutoInputsContainerStyle>
          </Grid>
        </InputsContainer>

        <InputsContainer title={"Fournisseur"}>
          <ButtonContainerStyle style={{ float: "right" }} item xs={12} sm={4}>
            <AddButton onAddClick={openAddingProviderDialogStatus} title={"Ajouter fournisseur"} />
          </ButtonContainerStyle>
          <Grid container spacing={1} style={{ justifyContent: "center", alignItems: "center" }}>
            <AutoInputsContainerStyle item xs={12} md={12} lg={12} style={{ position: "absolute", top: 0, right: 0, }}>

            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              {suppliersData && (
                <SelectInput
                required={addingData.status=="1"?false:true}
                  none={true}
                  label={"Fournisseur"}
                  error={addingError.provider_id}
                  value={addingData.provider_id ?? ""}
                  onChange={onChangeSelectProvider("provider_id")}
                  data={suppliersData.data?.map((e: ISuppliersData) => ({
                    id: e.id,
                    name: e.company_name,
                  }))}
                />
              )}
            </AutoInputsContainerStyle>

          </Grid>
        </InputsContainer>
        <InputsContainer title={"Marque"}>
          <ButtonContainerStyle style={{ float: "right" }} item xs={12} sm={4}>
            <AddButton onAddClick={openAddingBrandDialogStatus} title={"Ajouter marque"} />
          </ButtonContainerStyle>
          <Grid container spacing={1} style={{ justifyContent: "center", alignItems: "center" }}>
            <AutoInputsContainerStyle item xs={12} md={12} lg={12} style={{ position: "absolute", top: 0, right: 0, }}>

            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              {brandData && (
                <SelectInput
                required={addingData.status=="1"?false:true}
                  none={true}
                  label={"Marque"}
                  error={addingError.brand}
                  value={addingData.brand ?? ""}
                  onChange={onChangeSelectBrand("brand")}
                  data={brandData.data?.map((e: IBrandData) => ({
                    id: e.id,
                    name: e.name,
                  }))}
                />
              )}
            </AutoInputsContainerStyle>

          </Grid>
        </InputsContainer>




        <InputsContainer title={t("product.images_add")}>
          <Grid container spacing={1} style={{ justifyContent: "center" }}>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <Button
                type="submit"
                title={t("product.image_add")}
                onClick={openAddingDialogContainer}
              />
                
            </AutoInputsContainerStyle>
         
          </Grid>
          {addingError.image &&
          <span style={{color:'red'}}>Image obligatoire</span>
        }
          {addingImage.length > 0 && (
            <ImageList sx={{ maxHeight: 600 }} cols={4} rowHeight={240}>
              {addingImage.map(
                (item: any) =>
                  item.file && (
                    <ImageListItemStyle key={item.color_name + item.file}>
                      <IconButton key={item.color_hex} style={{ color: "red", position: "absolute", right: "4%" }} onClick={() => handleDeleteImage(item)}>
                        <CancelIcon />
                      </IconButton>
                      <ImageGalleryStyle
                        srcSet={URL.createObjectURL(item.file)}
                        src={URL.createObjectURL(item.file)}
                        alt={item.color_name}
                        loading="lazy"
                      />

                      <ImageListItemBar
                      sx={{ minHeight: 48 }}
                        title={item.color_name}
                        actionIcon={
                          item.is_main_image && (
                            <IconButton
                              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                              aria-label={`info about`}
                            >
                              <LibraryAddCheckIcon />
                            </IconButton>

                          )
                        }

                      />

                    </ImageListItemStyle>
                  )
              )}
            </ImageList>
          )}
        </InputsContainer>

        <Dialog
          open={showNutri}
          handleClose={(() => setShowNutri(false))}
          title={t("product.nutriscore_data.add")}
        >
          <form >
            <InputStyle>
              <TextInput
                value={nutrivalue.name}
                label={t("product.nutriscore_data.name")}
                onChange={onChangeNutriValue("name")}
              />
            </InputStyle>
            <InputStyle>
              <TextInput
                value={nutrivalue.value}
                label={t("product.nutriscore_data.value")}
                onChange={onChangeNutriValue("value")}
              />
            </InputStyle>

            <DeleteButtonsStyle>
              <ContainerButtonStyle>
                <Button
                  title={t("common.cancel_button")}
                  onClick={() => setShowNutri(false)}
                />
              </ContainerButtonStyle>
              <ContainerButtonStyle>
                <Button
                  onClick={() => {
                    setAddingData((prevObject: any) => ({
                      ...prevObject,
                      nutrivalues: [
                        ...prevObject.nutrivalues,
                        { id: counter, name: nutrivalue.name, value: nutrivalue.value },
                      ]
                    }));
                    setCounter((prevCounter: number) => prevCounter + 1)

                    setShowNutri(false); setNutriValue({ id: nutrivalue.id++, name: "", value: "" });
                  }}
                  title={t("common.confirm_button")}
                />
              </ContainerButtonStyle>
            </DeleteButtonsStyle>
          </form>
        </Dialog>
        <Dialog
          open={openEditNutriValueDialog}
          handleClose={(() => setOpenEditNutriValueDialog(false))}
          title={t("product.nutriscore_data.edit")}
        >
          <form onSubmit={onSuccessEditingDialog}>
            <InputStyle>
              <TextInput
                value={editedNutiValue?.name}
                label={t("product.nutriscore_data.name")}
                onChange={onChangeEditedNutriValue("name")}
              />
            </InputStyle>
            <InputStyle>
              <TextInput
                value={editedNutiValue?.value}
                label={t("product.nutriscore_data.value")}
                onChange={onChangeEditedNutriValue("value")}
              />
            </InputStyle>

            <DeleteButtonsStyle>
              <ContainerButtonStyle>
                <Button
                  title={t("common.cancel_button")}
                  onClick={() => setOpenEditNutriValueDialog(false)}
                />
              </ContainerButtonStyle>
              <ContainerButtonStyle>
                <Button
                  type="submit"
                  title={t("common.confirm_button")}
                  onClick={(e: any) => { onSuccessEditingDialog(e); window.scrollTo(0, 0); }
                  }
                />
              </ContainerButtonStyle>
            </DeleteButtonsStyle>
          </form>
        </Dialog>
        <AddTax
          openAddingDialog={openModalTax}
          setOpenAddingDialog={setOpenModalTax}
        ></AddTax>

        <Dialog
          open={openAddingDialog}
          handleClose={closeAddingDialogContainer}
          title={t("product.image_add")}
        >
          <form onSubmit={onSuccessAddingDialog}>

            <InputStyle>

            </InputStyle>
            {!isMainImageSelected && (
              <InputStyle>
                <FormControlLabel
                  control={
                    <Switch
                      checked={addingOneImage.is_main_image ? true : false}
                      onChange={onChangeOneImage("is_main_image")}
                      name={t("product.is_main_image")}
                    />
                  }
                  label={t("product.is_main_image")}
                />
              </InputStyle>
            )}
            <Grid container spacing={1}>
              <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                <TextInput
                  value={addingOneImage.color_name}
                  label={t("product.color_name")}
                  onChange={onChangeOneImage("color_name")}
                  error={t(addingOneImageError.color_name)}
                />

              </AutoInputsContainerStyle>
              <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                <TextInput
                  value={addingOneImage.color_hex}
                  label={t("product.color_hex")}
                  onChange={onChangeOneImage("color_hex")}
                  error={t(addingOneImageError.color_hex)}
                  type="color"
                  disabled
                />
              </AutoInputsContainerStyle>
            </Grid>


            <InputStyle>
              <FileInput
                value={addingOneImage.file ?? null}
                label={t("product.file")}
                onChange={onChangeOneImage("file")}
                error={t(addingOneImageError.file)}
                accept={GlobalVariables.FILE_FORMATS.map((e) => e.format).join(
                  ","
                )}
              />
            </InputStyle>

            {addingOneImage.file && (
              <ImageContainerStyle>
                <ImageStyle
                  src={URL.createObjectURL(addingOneImage.file)}
                ></ImageStyle>
              </ImageContainerStyle>
            )}


            <DeleteButtonsStyle>
              <ContainerButtonStyle>
                <Button
                  title={t("common.cancel_button")}
                  onClick={closeAddingDialogContainer}
                />
              </ContainerButtonStyle>
              <ContainerButtonStyle>
                <Button type="submit"
                  title={t("common.confirm_button")} />
              </ContainerButtonStyle>
            </DeleteButtonsStyle>

          </form>

        </Dialog>

        <InputsContainer title={"Valeurs nutritionnelles"}>
          <Grid container spacing={1} style={{ justifyContent: "center" }}>

            <ButtonContainerStyle item xs={12} sm={12}>

              <AddButton
                onAddClick={() => { setShowNutri(!showNutri) }}
                title={t("common.add")}
              />
            </ButtonContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
              <Table
                loader={false}
                headerData={[
                  {
                    key: "name",
                    title: t("product.nutriscore_data.name"),
                  },
                  {
                    key: "value",
                    title: t("product.nutriscore_data.value"),
                  },
                  { key: "action", title: t("common.actions") },

                ]}
                bodyData={addingData.nutrivalues.map((categ: {
                  id: number;
                  name: string;
                  value: string
                }) => {
                  return {
                    name: <p>{categ.name}</p>,
                    value: <p>{categ.value}</p>,
                    action: (
                      <ActionContainerStyle>

                        {
                          <Tooltip title={t("common.edit")}>
                            <IconButton
                              color="primary"
                              component="span"
                              onClick={openEditNutriValueDialogStatus(categ.id, categ.name, categ.value)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        }
                        {
                          <Tooltip title={t("common.delete")}>
                            <IconButton
                              color="primary"
                              component="span"
                              onClick={openDeleteNutriValueDialogStatus(categ.name)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        }
                      </ActionContainerStyle>
                    ),

                  }
                })}

              />
            </AutoInputsContainerStyle>
          </Grid>
        </InputsContainer>


        <Grid container spacing={1}  >
          <ButtonContainerStyle item xs={12} sm={6} style={{ justifyContent: "left" }}>
            {hasError &&
              <p style={{ position: "relative", color: "red", paddingLeft: 5 }}><b>{"   "}</b>Une erreur est survenue. Veuillez la corriger et réessayer.</p>
            }
          </ButtonContainerStyle>
          <ButtonContainerStyle item xs={12} sm={6}>

            <DeleteButtonsStyle>

              <ContainerButtonStyle>
                <Button
                  title={t("common.cancel_button")}
                  onClick={() => navigate(-1)}
                />
              </ContainerButtonStyle>
              <ContainerButtonStyle>
                <Button
                  type="submit"
                  onClick={(e: any) => { addProductForm(); sethasError(false); }}
                  title={t("common.confirm_button")}
                />
              </ContainerButtonStyle>
            </DeleteButtonsStyle>

          </ButtonContainerStyle>

        </Grid>

        {deletedNutiValue && (
          <DeleteNutriValue
            name={deletedNutiValue.name}
            openDeleteDialog={openDeleteNutriValueDialog}
            setOpenDeleteDialog={setOpenDeleteNutriValueDialog}
            deleteFunction={() => { updateNutriValuesAfterDelete(deletedNutiValue.name) }}
          ></DeleteNutriValue>
        )}

        <AddSupplier
          openAddingDialog={showModalAddProvider}
          addFromProduct={true}
          setOpenAddingDialog={setShowModalAddProvider}
        ></AddSupplier>
        <AddBrand
          openAddingDialog={showModalAddBrand}
          addFromProduct={true}
          setOpenAddingDialog={setShowModalAddBrand}
        ></AddBrand>

      </DashboardLayout>



    </>
  );
}
export default ProductAdd;
