import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IProductData } from "./Product.types";
import { Grid, IconButton, Tooltip, Checkbox, Button } from "@mui/material";
import moment from "moment";
import StatusBadge from "components/common/StatusBadge/StatusBadge";
import AddButton from "components/common/AddButton/AddButton";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import DraftButton from "components/common/DraftButton/DraftButton";
import PublishButton from "components/common/PublishButton/PublishButton";
import SelectInput from "components/common/SelectInput/SelectInput";
import { ICategoryData } from "features/Category/CategoryList/Category.types";
import ButtonGroup from '@mui/material/ButtonGroup';
import SearchInput from "components/common/SearchInput/SearchInput";
import { ActionContainerStyle, ButtonContainerStyle, InputStyle } from "./Product.style";
import { Delete, Edit, FileCopy } from "@mui/icons-material";
import { useCallback, useState } from "react";
import useProductList from "./useProductList";
import DeleteProduct from "../ProductDelete/DeleteProduct";
import DupliqueProduct from "../ProductDuplique/DupliqueProduct";
import { useNavigate } from "react-router-dom";
import { useDeleteMultipleProductMutation, useGetPublishDraftProductMutation } from "redux/api/product/product"
import BarcodeScanner from "components/common/BarcodeScanner/BarcodeScanner";
import Barcode from 'react-barcode';
import { useSnackBar } from "hooks/useSnackBar";
import DeleteProductMultiple from "../ProductDeleteMultiple/DeleteProductMultiple";
import Dialog from "components/common/Dialog/Dialog";
import TextInput from "components/common/TextInput/TextInput";
import { ContainerButtonStyle, DeleteButtonsStyle } from "../ProductAdd/Product.style";
import FileInput from "components/common/FileInput/FileInput";
import AddCSV from "../AddModalProductCSV/AddModalProductCSV";
import ImportButton from "components/common/ImportButton/ImportButton";
import PublishProduct from "../ProductPublish/PublishProduct";
import DraftProduct from "../ProductDraft/DraftProduct";
import ProductPublish from "../ProductDraftPublishAlert/ProductDraftPublishAlert";
import ProductPublishAlert from "../ProductDraftPublishAlert/ProductDraftPublishAlert";
function Product() {
  const [isScannerModalOpen, setScannerModalOpen] = useState(false);
  const [confirmedBarcode, setConfirmedBarcode] = useState<string | null>(null);
  const handleOpenScannerModal = () => {
    setScannerModalOpen(true);

  };
  const { t } = useTranslation()


  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false)
  const [openDraftModal, setOpenDraftModal] = useState(false)
  const [openPublishModal, setOpenPublishModal] = useState(false)
  const [openPublishModalAlert, setOpenPublishModalAlert] = useState(false)

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const handleCloseScannerModal = () => {
    setScannerModalOpen(false);
  };
  const openPublishDialogStatusAlert = () => {
    setOpenPublishModalAlert(true);

  };
  const openDeleteMultipleDialogStatus = () => {
    setOpenDeleteMultipleModal(true);

  };
  const openDraftDialogStatus = () => {
    setOpenDraftModal(true);

  };
  const openPublishDialogStatus = () => {
    setOpenPublishModal(true);

  };
  const [deleteCode, { data, error, isLoading: loading, reset }] = useDeleteMultipleProductMutation()
  const [publishDraft, { data: publishDraftData, error: ErrorPublishDraft, isLoading: loadingDraft, reset: resetPubDra }] = useGetPublishDraftProductMutation()

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_deleted"));
    reset();

  }
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  if (ErrorPublishDraft) {
    displayErrorApiSnackBar(ErrorPublishDraft);
    resetPubDra();
  }
  if (publishDraftData?.data == "success" && publishDraftData?.data) {
    console.log("publish", publishDraftData?.data)
    displaySuccessSnackBar(t("product.status_updated"));
    resetPubDra();
  }




  const handleConfirmBarcode = (barcode: string | null) => {
    // Handle the confirmed barcode value
    setConfirmedBarcode(barcode);
    // Close the scanner modal
    setScannerModalOpen(false);
    onSearchChange(barcode ?? '');
  };

  const navigate = useNavigate();

  const {
    onChangeSelectCategory,
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    productData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    categoryData,
    category,
    subcategories,
    subCategory_id,
    onChangeSelectSubCategory,
    changeFilter,
    filter
  } = useProductList(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openDupliqueDialog, setOpenDupliqueDialog] = useState<boolean>(false);
  const [showModalImportCSV, setShowModalImportCSV] = useState(false)
  const [dupliquedProduct, setDupliquedProduct] = useState<{
    id: number;
    title: string;
  }>();
  const [deletedProduct, setDeletedProduct] = useState<{
    id: number;
    title: string;
  }>();
  const openDeleteDialogStatus = useCallback(
    (id: number, title: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedProduct({ id: id, title: title });
    },
    [setOpenDeleteDialog, setDeletedProduct]
  );
  const openDupliqueDialogStatus = useCallback(
    (id: number, title: string) => () => {
      setOpenDupliqueDialog(true);
      setDupliquedProduct({ id: id, title: title });
    },
    [setOpenDupliqueDialog, setDupliquedProduct]
  );
  const navigateToAddPage = () => {
    navigate("/" + RouteIdEnum.Products + "/" + RouteIdEnum.Add);
  };
  const handleShowModalExportCSV = () => {
    setShowModalImportCSV(!showModalImportCSV)
  }

  const navigateToEditPage = useCallback(
    (id: number) => () => {
      console.log("id", id)

      navigate("/" + RouteIdEnum.Products + "/" + RouteIdEnum.Edit + "/" + id);
    },
    [navigate]
  );
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };

  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };



  const deleteMultiple = (event: any) => {
    event.preventDefault()
    deleteCode({
      product_ids: selectedItems,
      select_all: selectAll,
      search: search
    })
    if (!error) {
      setOpenDeleteMultipleModal(false)
    }
  }
  const publishDraftHandler = (e: any, status: number) => {

    e.preventDefault()
    publishDraft({
      product_ids: selectedItems,
      select_all: selectAll,
      search: search,
      status: status
    })
    if (!error) {
      setOpenDraftModal(false)
      setOpenPublishModal(false)

    }
  }
  return (
    <DashboardLayout
      title={t("product.page_title")}
      selectedPath={RouteIdEnum.Products}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>

        <Grid item xs={12} sm={4}>

          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <Button onClick={handleOpenScannerModal}> Scanner code à barre </Button>

        <ButtonContainerStyle item xs={12} sm={4}>
          {((selectAll || selectedItems.length != 0)) && <DraftButton onAddClick={openDraftDialogStatus} title={t("common.draft")} />
          }
          {((selectAll || selectedItems.length != 0)) && <PublishButton onAddClick={openPublishDialogStatus} title={t("common.publish")} />
          }
          {((selectAll || selectedItems.length != 0)) && <DeleteButton onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
          }
          &nbsp;
          <AddButton onAddClick={navigateToAddPage} title={t("common.add")} />
          <ImportButton onAddClick={handleShowModalExportCSV} title={t("common.import_csv")} />

        </ButtonContainerStyle>
      </Grid>
      <Grid container mb={2} justifyContent="space-between" spacing={1}>

        <Grid item xs={12} sm={4}>

          {categoryData && (
            <SelectInput
              none={true}
              label={t("product.category")}
              error={""}
              value={category}
              onChange={onChangeSelectCategory}
              data={categoryData.data?.map((e: ICategoryData) => ({
                id: e.id,
                name: e.title,
              }))}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>

          {subcategories && (
            <SelectInput
              none={true}
              label={t("product.subcategory")}
              error={""}
              value={subCategory_id}
              onChange={onChangeSelectSubCategory}
              data={subcategories.data?.map((e: ICategoryData) => ({
                id: e.id,
                name: e.title,
              }))}
            />
          )}
        </Grid>

        <ButtonContainerStyle item xs={12} sm={4}>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button onClick={() => changeFilter('all')} variant={filter === 'all' ? 'contained' : 'outlined'}>Tous</Button>
            <Button onClick={() => changeFilter('inStock')} variant={filter === 'inStock' ? 'contained' : 'outlined'}>En stock</Button>
            <Button onClick={() => changeFilter('outOfStock')} variant={filter === 'outOfStock' ? 'contained' : 'outlined'}>En rupture</Button>
          </ButtonGroup>
        </ButtonContainerStyle>
      </Grid>
      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: <Checkbox color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "title",
            title: t("product.title"),
            onClick: onOrderChange("title"),
            asc: orderBy === "title" ? orderDir === "asc" : undefined,
          },
          {
            key: "description",
            title: t("product.description"),
          },
          {
            key: "price",
            title: t("product.price"),
            onClick: onOrderChange("price"),
            asc: orderBy === "price" ? orderDir === "asc" : undefined,
          },
          {
            key: "quantity",
            title: t("product.quantity"),
            onClick: onOrderChange("quantity"),
            asc: orderBy === "quantity" ? orderDir === "asc" : undefined,
          },
          {
            key: "created_at",
            title: t("common.created_at"),
          },
          {
            key: "barcode",
            title: "barcode",
            onClick: onOrderChange("quantity"),
            asc: orderBy === "quantity" ? orderDir === "asc" : undefined,
          },
          { key: "stock", title: "stock" },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={productData?.data.map((e: IProductData) => {
          return {
            checkbox: (
              <Checkbox checked={(!selectAll && selectedItems.includes(e.id)) || (selectAll && !selectedItems.includes(e.id))}
                onChange={() => handleCheckboxChange(e.id)}
              />

            ),

            title: <p>{e.title}</p>,
            description: <p>
              {e.description && e.description.length > 30
                ? e.description.substring(0, 30) + "..."
                : e.description}
            </p>,
            price: <p>{e.price}</p>,
            quantity: <p>{e.quantity}</p>,
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            barcode: e.barcode ? <div style={{ width: '100%', display: "flex" }}>
              <Barcode value={`${e.barcode}`} height={30} fontSize={15} />
            </div> : null,
            stock: e.quantity && e.stock_alert ? <StatusBadge color={e.quantity <= e.stock_alert ? "error" : "success"} /> : <StatusBadge color={'default'} />,
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip title={'Dupliquer'}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDupliqueDialogStatus(e.id, e.title)}
                    >
                      <FileCopy />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={navigateToEditPage(e.id)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.title)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }

              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: productData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {showModalImportCSV && <AddCSV openImportCSVDialog={showModalImportCSV} setOpenImportingCSVDialog={setShowModalImportCSV} />
      }
      {deletedProduct && (
        <DeleteProduct
          id={deletedProduct.id}
          title={deletedProduct.title}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteProduct>
      )}
      {dupliquedProduct && (
        <DupliqueProduct
          id={dupliquedProduct.id}
          title={dupliquedProduct.title}
          openDupliqueDialog={openDupliqueDialog}
          setOpenDupliqueDialog={setOpenDupliqueDialog}
        ></DupliqueProduct>
      )}
      {selectedItems && (
        <DeleteProductMultiple
          isLoading={loading}
          product_ids={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteProductMultiple>
      )}
      {publishDraftData?.data !== "success" && publishDraftData?.data && <ProductPublishAlert
        isLoading={loading}
        products={publishDraftData?.data}
        openPublishProduct={openPublishModalAlert}
        setOpenPublishProduct={setOpenPublishModalAlert}
        onSubmit={() => { }}
      ></ProductPublishAlert>
      }
      {selectedItems && (
        <PublishProduct
          isLoading={loading}
          product_ids={selectedItems}
          openDeleteMultipleDialog={openPublishModal}
          setOpenDeleteMultipleDialog={setOpenPublishModal}
          onSubmit={(e: any) => { e.preventDefault(); publishDraftHandler(e, 2); openPublishDialogStatusAlert(); setOpenPublishModal(false); }}
        ></PublishProduct>
      )}
      {selectedItems && (
        <DraftProduct
          isLoading={loading}
          product_ids={selectedItems}
          openDeleteMultipleDialog={openDraftModal}
          setOpenDeleteMultipleDialog={setOpenDraftModal}
          onSubmit={(e: any) => publishDraftHandler(e, 1)}
        ></DraftProduct>
      )}

      {isScannerModalOpen && <BarcodeScanner
        isOpen={isScannerModalOpen}
        onClose={handleCloseScannerModal}
        onConfirm={handleConfirmBarcode}
      />}
    </DashboardLayout>
  );
}
export default Product;
