import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const brandApi:any = createApi({
  reducerPath: "BrandApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Brand"],
  endpoints: (builder) => ({
    getAllBrand: builder.query({
      query: (params: { paginator?: Paginator,all?:boolean }) => {
        return injectParamsPaginator(ENDPOINTS.BRAND, params.paginator,params.all);
      },
      providesTags: ["Brand"],
    }),
    getBrand: builder.query({
      query: (id: number) => ({
        url: `${ENDPOINTS.BRAND}/${id}`,
        method: "GET",
      }),
    }),
    deleteBrand: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.BRAND}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Brand"],
    }),
    deleteMultipleBrands: builder.mutation({
      query: (params: { brands_ids: number[], select_all: boolean ,search:string}) => ({
        url: `${ENDPOINTS.BRAND}/delete-multiple`,
        method: "POST",
        body: {
            brands_ids: params.brands_ids,
          select_all: params.select_all,
          search:params.search
        },
      }),
      invalidatesTags: ["Brand"],
    }),

    addBrand: builder.mutation<{}, FormData>({
      query(formData: FormData) {
        return {
          url: `${ENDPOINTS.BRAND}`,
          method: "POST",
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ["Brand"],
    }),
    updateBrand: builder.mutation({
      query({ id, formData }) {
        return {
          url: `${ENDPOINTS.BRAND}/${id}`,
          method: "POST",
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ['Brand'],


    }),
    getBrandById:builder.query({
      query: (params: { paginator?: Paginator,id:number }) => {
        return injectParamsPaginator(`${ENDPOINTS.BRAND}/${params.id}`, params.paginator);
      },
      }),
   


}
    )
  })








export const {
useGetAllBrandQuery,
useGetBrandByIdQuery,
useAddBrandMutation,
useUpdateBrandMutation,
useDeleteBrandMutation,
useDeleteMultipleBrandMutation
} = brandApi;
