import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "config/constant";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { ISuppliersAddingData } from "features/Supplier/SupplierAdd/AddSupplier.types";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const suppliersApi = createApi({
  reducerPath: "SuppliersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Providers"],
  endpoints: (builder) => ({
    getSuppliersById: builder.query({
      query: (id: string) => ENDPOINTS.SUPPLIERS +"/"+ id,
    }),
    getAllSuppliers: builder.query({
      query: (params: { paginator?: Paginator }) => {
        return injectParamsPaginator(
          ENDPOINTS.SUPPLIERS,
          params.paginator
        );
      },
      providesTags: ["Providers"],
    }),
    getAllSuppliersWithoutPagination: builder.query({
      query: (params: { paginator?: Paginator }) => {
        return injectParamsPaginator(
          `${ENDPOINTS.SUPPLIERS}/all`,
          params.paginator
        );
      },
      providesTags: ["Providers"],
    }),
    deleteSupplier: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.SUPPLIERS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Providers"],
    }),
    addSupplier: builder.mutation({
      query: ({
        company_name,
        address,
        postal_code,
        city,
        region,
        department,
        country,
        email ,
        office_phone,
        mobile_phone,
        website,
        contact_last_name,
        contact_first_name,
        contact_position,
        contact_mobile,
        contact_email,
        contact_office_phone
      }: ISuppliersAddingData) => ({
        url: `${ENDPOINTS.SUPPLIERS}`,
        method: "POST",
        params: {  company_name,
            address,
            postal_code,
            city,
            region,
            department,
            country,
            email ,
            office_phone,
            mobile_phone,
            website,
            contact_last_name,
            contact_first_name,
            contact_position,
            contact_mobile,
            contact_email,
            contact_office_phone },
      }),
      invalidatesTags: ["Providers"],
    }),
    deleteMultipleSuppliers: builder.mutation({
      query: (params: { providers_ids: number[], select_all: boolean ,search:string}) => ({
        url: `${ENDPOINTS.SUPPLIERS}/delete-multiple`,
        method: "POST",
        body: {
          providers_ids: params.providers_ids,
          select_all: params.select_all,
          search:params.search
        },
      }),
      invalidatesTags: ["Providers"],

    }),
    updateSupplier: builder.mutation({
      query: ({
        id,
        company_name,
        address,
        postal_code,
        city,
        region,
        department,
        country,
        email ,
        office_phone,
        mobile_phone,
        website,
        contact_last_name,
        contact_first_name,
        contact_position,
        contact_mobile,
        contact_email,
        contact_office_phone
      }: ISuppliersAddingData) => ({
        url: `${ENDPOINTS.SUPPLIERS}/${id}`,
        method: "POST",
        params: {  company_name,
            address,
            postal_code,
            city,
            region,
            department,
            country,
            email ,
            office_phone,
            mobile_phone,
            website,
            contact_last_name,
            contact_first_name,
            contact_position,
            contact_mobile,
            contact_email,
            contact_office_phone },
      }),
      invalidatesTags: ["Providers"],
    }),
  }),
});

export const {
  useGetAllSuppliersQuery,
  useGetSuppliersByIdQuery,
  useDeleteSupplierMutation,
  useAddSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteMultipleSuppliersMutation,
  useGetAllSuppliersWithoutPaginationQuery
} = suppliersApi;
