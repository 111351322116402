import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
} from "./DeleteExpeditionCategory.style";
import { useSnackBar } from "hooks/useSnackBar";
import { IExpeditionCategoryDelete } from "./DeleteExpeditionCategory.types";
import { useDeleteCategoryMutation } from "redux/api/category/category";
import { useDeleteTaxMutation } from "redux/api/tax/taxes";
import { useDeleteExpeditionCategMutation } from "redux/api/delivery/expeditionCategs/expeditionCategs";

function DeleteExpeditionCategory({
  id,
  name,
  openDeleteDialog,
  setOpenDeleteDialog,
}: IExpeditionCategoryDelete) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [deleteCode, { data, error, isLoading, reset }] =
    useDeleteExpeditionCategMutation();

  if (error) {
    displayErrorApiSnackBar(error);
  }
  if (data) {
    displaySuccessSnackBar(t("tax.successfully_deleted"));
    reset();
  }

  const closeDeleteAlertDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, [setOpenDeleteDialog]);

  const onSuccessDeleteAlert = (event: React.FormEvent) => {
    event.preventDefault();
    deleteCode(id);
    closeDeleteAlertDialog();
  };

  return (
    <Dialog
      open={openDeleteDialog}
      handleClose={closeDeleteAlertDialog}
      title={t("delivery.delete_cat")}
    >
      <form onSubmit={onSuccessDeleteAlert}>
        <p>
          {t("common.delete_message")}
          <span style={{ fontWeight: "bold", marginLeft: 5 }}>{name}</span>!
        </p>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeDeleteAlertDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default DeleteExpeditionCategory;
