import { isUndefined } from "lodash";
import { Paginator } from "types/interfaces";
import { arrayToParams, injectPaginator } from "utils/services/api.service";

export const apiUrl = "/";

export enum ApiUrlsEnum {}

// Inject params in a request
export function injectParams(
  url: string,
  object: Record<string, unknown>
): string {
  let result = `${url}?`;
  // Get all keys from object
  const keys = Object.keys(object);
  // Iterate over keys
  keys.forEach((key, index) => {
    const value = object[key];
    if (Array.isArray(value)) {
      result += arrayToParams(key, value);
    } else if (!isUndefined(value)) {
      // Adding key=value to result
      result += `${key}=${value}`;
      // Adding & if it's not the last param
      if (index < keys.length - 1) {
        result += "&";
      }
    }
  });
  // Remove the last "&"" from string
  if (result.endsWith("&")) {
    result = result.slice(0, -1);
  }
  return result;
}

export function injectParamsPaginator(
  url: string,
  paginator?: Paginator,
  all?:boolean,
  isAdmin?:boolean,
  category?:any,
  Subcategory?:any,
  Stock?:any
): string {
  return injectPaginator(url, paginator,all,isAdmin,category,Subcategory,Stock);
}

export function injectParameters(endpoints: string[]): string {
  return `${endpoints.join("/")}`;
}
