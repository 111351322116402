import {
  Input,
  InputLabel,
  TextField,
  TextareaAutosize,
  styled,
} from "@mui/material";

export const FragmentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexFlow: "column",
}));

export const TitleStyle = styled("p")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  margin: 0,
  padding: 0,
}));

export const ConatinerStyle = styled("div")(({ theme }) => ({
  width: "100%",
  position: "relative",
  marginTop: 8,
}));

export const InputConatinerStyle = styled("div")(({ theme }) => ({
  width: "100%",
}));

export const PasswordMultipleStyle = styled(TextareaAutosize)(({ theme }) => ({
  color: "black",
  fontSize: 14,
  outline: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 5,
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 12,
  paddingRight: 40,
  transition: "0.1s ease-out",
  width: "100%",
  "&:disabled": {
    backgroundColor: "white",
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: 0,
      transform: "translateY(-70%) scale(1)",
    },
  },
}));

export const InputMultipleStyle = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: '"Lato, Regular"',
  color: "black",
  fontSize: 14,
  outline: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 5,
  padding: 10,
  transition: "0.1s ease-out",
  width: "100%",
  "&:disabled": {
    backgroundColor: theme.palette.grey[100],
  },
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  '&[type="date"]': {
    padding: 12,
  },
  '&[type="date"]::-webkit-calendar-picker-indicator': {
    display: "block",
    // backgroundImage: `url(${calendar})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
    width: 17,
    height: 17,
    padding: 0,
    margin: 0,
    borderWidth: 0,
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: 0,
      transform: "translateY(-70%) scale(1)",
    },
  },
}));

export const PasswordStyle = styled(Input)(({ theme }) => ({
  color: "black",
  fontSize: 14,
  outline: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 5,
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 12,
  paddingRight: 40,
  transition: "0.1s ease-out",
  width: "100%",
  "&:disabled": {
    backgroundColor: "white",
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: 0,
      transform: "translateY(-70%) scale(1)",
    },
  },
}));
export const InputStyle = styled("input")(({ theme }) => ({
  color: "black",
  fontSize: 14,
  outline: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 5,
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 12,
  paddingRight: 40,
  transition: "0.1s ease-out",
  width: "100%",
  "&:disabled": {
    backgroundColor: "white",
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: "0px!important",
      transform: "translateY(-70%) scale(1)!important",
    },
  },
}));
export const InputStyle2 = styled(Input)(({ theme }) => ({
  fontFamily: '"Lato, Regular"',
  color: "black",
  fontSize: 14,
  outline: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 5,
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 12,
  paddingRight: 40,
  transition: "0.1s ease-out",
  width: "100%",
  "&:disabled": {
    backgroundColor: "white",
  },
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  '&[type="date"]': {
    padding: 12,
  },
  '&[type="date"]::-webkit-calendar-picker-indicator': {
    display: "block",
    // backgroundImage: `url(${calendar})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
    width: 17,
    height: 17,
    padding: 0,
    margin: 0,
    borderWidth: 0,
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: 0,
      transform: "translateY(-70%) scale(1)",
    },
  },
}));

export const LabelStyle = styled(InputLabel)(({ theme }) => ({
  position: "absolute",
  fontZize: "1rem",
  left: 0,
  height: 18.5,
  top: 0,
  transform: "translateY(-70%)",
  backgroundColor: "transparent",
  backgroundSize: "100% 50%",
  backgroundPosition: "top left",
  color: theme.palette.primary.main,
  paddingRight: 1,
  paddingLeft: 1,
  margin: "0 18px",
  transition: "0.1s ease-out",
  transformOrigin: "left top",
  pointerEvents: "none",
  fontSize: 15,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "calc(100% - 20px)",
  textShadow:
    "1px 0 1px #fff, -1px 0 1px #fff, 0 1px 1px #fff, 0 -1px 1px #fff",
}));

export const DeviderStyle = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  boxShadow: "0 1px 1px white",
  height: 5,
  backgroundColor: "white",
  top: 12,
  zIndex: -1,
}));

export const PasswordIconStyle = styled("img")(({ theme }) => ({
  height: 16,
  width: 16,
  position: "absolute",
  top: 15,
  right: 10,
}));

export const ErrorStyle = styled("p")(({ theme }) => ({
  color: "red",
  fontSize: 14,
  margin: 0,
  marginTop: 2,
  marginBottom: 0,
  textAlign: "start",
}));

export const InputNumberStyle = styled("input")(({ theme }) => ({
  color: "black",
  fontSize: 14,
  outline: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 5,
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 12,
  paddingRight: 40,
  transition: "0.1s ease-out",
  width: "100%",
  "&:disabled": {
    backgroundColor: "white",
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: "0px!important",
      transform: "translateY(-70%) scale(1)!important",
    },
  },
}));

export const InputDateStyle = styled(TextField)(({ theme }) => ({
  color: "black",
  backgroundColor: "white",
  fontSize: 14,
  outline: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 5,
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 12,
  paddingRight: 40,
  transition: "0.1s ease-out",
  width: "100%",
  "&:disabled": {
    backgroundColor: "white",
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: 0,
      transform: "translateY(-70%) scale(1)",
    },
  },
}));
