export const delivery={
    name:"nom",
    description:"description",
    region:"region",
    horaire:"horaire",
    start_time:"De",
    end_time:"A",
    delivery_title:"Livraisons",
    code:"Code ",
    successfully_deleted: "Catégorie d'expédition  supprimée avec succès",
    successfully_adding: "Catégorie d'expédition  ajoutée avec succès",
    successfully_editing: "Catégorie d'expédition  editée avec succès",
    successfully_deleted_mod: "Mode d'expédition  supprimée avec succès",
    successfully_adding_mod: "Mode d'expédition  ajoutée avec succès",
    successfully_editing_mod: "Mode d'expédition  editée avec succès",
    delete_cat: "Supprimer la Catégorie d'expédition",
    add_cat: "Ajouter la Catégorie d'expédition",
    edit_cat: "Modifier la Catégorie d'expédition",    
    delete_mod: "Supprimer le Mode d'expédition",
    add_mod: "Ajouter le Mode d'expédition",
    edit_mod: "Modifier le Mode d'expédition",
    value:"Valeur",
    mode:"Mode",
    tax:"Taxe",
    category:"Catégorie",
    info_add:"Informations sur le mode d'expédition",
    categories:"Catégories d'expédition",
    id_prod:"Id_produit",
    add:"Ajouter Mode d'expédition",
    start_end_error:"De doit être inférieur à A",
    msg:"Les valeurs des des modes d'\expédition associés à cette catégorie d'\expédition sont 0.Voulez vous les modifier?",
    oui:"Oui",
    non:"Non",
    mode_exped:"Mode d'expédition"
}