import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState, useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./EditPromotionFlash.style";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import {
  IPromotionFlashEditingData,
  IPromotionFlashEditingDataError,
  IEditingDialog,
} from "./EditPromotionFlash.types";
import SelectInput from "components/common/SelectInput/SelectInput";
import SelectInputShow from "components/common/SelectInput/SelectInputShow";
import { CheckBox } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AutoInputsContainerStyle } from "features/Product/ProductAdd/Product.style";
import useProductList from "features/Product/ProductList/useProductList";
import useCategoryList from "features/Category/CategoryList/useCategoryList";
import useSubCategoryList from "features/SubCategory/SubCategoryList/useSubCategoryList";
import { useUpdatePromotionFlashMutation } from "redux/api/promotionFlash/promotionFlash";
import { useGetAllSubCategoriesQuery } from "redux/api/category/category";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function EditPromotionFlash({
  iniData,
  openEditingDialog,
  setOpenEditingDialog,
}: IEditingDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [editingPromotionFlash, { data, error, isLoading, reset }] =
    useUpdatePromotionFlashMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("promo_flash.successfully_editing"));
    reset();
  }
  const { categoryData } = useCategoryList();
  const { productData } = useProductList(true);
  const { subCategoryData } = useGetAllSubCategoriesQuery();
  const closeEditingDialog = useCallback(() => {
    setOpenEditingDialog(false);
    setEditingData({
      start_date: "",
      end_date: "",
      percentage_value: "",
      link: "",
      products: "",
      categories: "",
      subCategories: "",
      subscriptions: "",
      eur_value: "",
      eur_or_percentage: "",
      description: "",
      value_type:"", promotion_value:"",
    });
  }, [setOpenEditingDialog]);
  const validate = () => {
    let ok = true
    let addingPromotionError: IPromotionFlashEditingDataError = {
      start_date: "",
      end_date: "",
      percentage_value:"",
      link: "",
      products:"",
      categories:"",
      subCategories:"",
      subscriptions:"",
      eur_value:"",
      eur_or_percentage:"",
      description:"",
      value_type:"", promotion_value:"",

    }
    if (editingData.description == "") {
      ok = false
      addingPromotionError.description = "Ce champ est obligatoire"
    }
    if (editingData.link == "") {
      ok = false
      addingPromotionError.link = "Ce champ est obligatoire"
    }
    if (editingData.value_type == "") {
      ok = false
      addingPromotionError.value_type = "Ce champ est obligatoire"
    }
    if (editingData.promotion_value == "") {
      ok = false
      addingPromotionError.promotion_value = "Ce champ est obligatoire"
    }
   
    if(editingData.start_date==null){
      ok = false
      addingPromotionError.start_date="Ce champ est obligatoire"
    }
    if(editingData.end_date==null){
      ok = false
      addingPromotionError.end_date="Ce champ est obligatoire"
    }
    console.log("ttttttyj",editingData);
    if(editingData.link=="1" && editingData.categories.length == 0){
      ok = false
      addingPromotionError.categories="Ce champ est obligatoire"
    }
    if(editingData.link=="2" && editingData.subCategories.length == 0){
      ok = false
      addingPromotionError.subCategories="Ce champ est obligatoire"
    }
    if(editingData.link=="3" && editingData.products.length == 0){
      ok = false
      addingPromotionError.products="Ce champ est obligatoire"
    }
    if (Number(editingData.percentage_value) < 0 || Number(editingData.percentage_value) > 100) {
      ok = false
      addingPromotionError.percentage_value = "Pourcentage entre 0 et 100"
    }
    if(editingData.end_date <= editingData.start_date){
      ok = false
      addingPromotionError.end_date="La date de fin doit être postérieure à la date de début"
    }
    console.log("addingPromotionError", addingPromotionError)
    setEditingError(addingPromotionError)
    return ok
  }
  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    if (validate()){
      editingPromotionFlash({
        id: editingData.id,
        start_date: editingData.start_date,
        description: editingData.description,
        end_date: editingData.end_date,
        link:editingData.link === "1"? "categories"  : editingData.link ===  "2"? "subcategories" : editingData.link ===  "3"? "products" : editingData.link,
        categories: editingData.categories,
        subCategories: editingData.subCategories,
        products: editingData.products,
        subscriptions: editingData.subscriptions,
        eur_or_percentage: editingData.eur_or_percentage,
        percentage_value: editingData.percentage_value,
        eur_value: editingData.eur_value,
        value_type:editingData.value_type, promotion_value:editingData.promotion_value,
      });
      if(!error)
      closeEditingDialog();
    }
  };
  console.log("initDatazz", iniData);
  const [editingData, setEditingData] = useState<IPromotionFlashEditingData>({
    id: iniData.id,
    percentage_value: iniData.percentage_value,
    start_date: iniData.start_date,
    end_date: iniData.end_date,
    link: iniData.link === "categories"? "1"  : iniData.link ===  "subcategories"?"2"  : iniData.link ===  "products"?"3"  : iniData.link,
    subscriptions: iniData.subscriptions,
    products: iniData.products,
    categories: iniData.categories,
    subCategories: iniData.subCategories,
    eur_value: iniData.eur_value,
    eur_or_percentage: iniData.eur_or_percentage,
    description: iniData.description,
    value_type:iniData.value_type, promotion_value:iniData.promotion_value,
  });
  const [editingError, setEditingError] =
    useState<IPromotionFlashEditingDataError>({
      percentage_value: "",
      start_date: "",
      end_date: "",
      subscriptions: "",
      link: "Liaison obligatoire",
      products: "",
      categories: "",
      subCategories: "",
      eur_value: "",
      eur_or_percentage: "",
      description: "",
      value_type:'', promotion_value:'',
    });
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditingData({ ...editingData, [field]: e.target.value });
        setEditingError({...editingError,[field]:""});
        if (field == "percentage") {
          if (Number(e.target.value) > 0 && Number(e.target.value) <= 100) {
            setEditingData({
              ...editingData,
              percentage_value: `${e.target.value}`,
            });
          } else {
            setEditingData({ ...editingData, percentage_value: "" });
          }
        }
        
       
      },
    [editingData]
  );

  useEffect(() => {
    if (openEditingDialog)
      setEditingData({
        id: iniData.id,
        percentage_value: iniData.percentage_value,
        start_date: iniData.start_date,
        end_date: iniData.end_date,
        link:  iniData.link === "categories"? "1"  : iniData.link ===  "subcategories"?"2"  : iniData.link ===  "products"?"3"  : iniData.link,
        products: iniData.products,
        categories: iniData.categories,
        subCategories: iniData.subCategories,
        eur_value: iniData.eur_value,
        subscriptions: iniData.subscriptions,
        eur_or_percentage: iniData.eur_or_percentage,
        description: iniData.description,
        value_type:iniData.value_type, promotion_value:iniData.promotion_value,
      });
  }, [
    openEditingDialog,
    iniData.id,
    iniData.percentage_value,
    iniData.start_date,
    iniData.link,
    iniData.products,
    iniData.categories,
    iniData.subCategories,
    iniData.subscriptions,
    iniData.eur_value,
    iniData.eur_or_percentage,
    iniData.description,
  ]);
  useEffect(()=>{
    editingData.eur_value!==null && editingData.percentage_value==null?setEditingData({
      ...editingData,
      eur_or_percentage: "euro",
    }):
    setEditingData({
      ...editingData,
      eur_or_percentage: "percentage",
    });
  },[iniData.eur_value,iniData.percentage_value])

  const onChangeSelectLink = (e: string) => {
    setEditingData({ ...editingData, link: e.toString() });
    console.log("editingData", editingData);
    setEditingError({ ...editingError, link: "" });
  };
  const onChangeSelectProductList = (e: string) => {
    setEditingData({ ...editingData, products: e });

    setEditingError({ ...editingError, products: "" });
  };
  const onChangeSelectCategory = (e: string) => {
    setEditingData({ ...editingData, categories: e });
    setEditingError({ ...editingError, categories: "" });
  };

  const onChangeSelectSubCategory = (e: string) => {
    setEditingData({ ...editingData, subCategories: e });

    setEditingError({ ...editingError, subCategories: "" });
  };
  useEffect(() => {
    console.log("add", editingData);
  }, [editingData]);

  return (
    <Dialog
      open={openEditingDialog}
      handleClose={closeEditingDialog}
      title={t("promo_flash.edit")}
    >
      <form onSubmit={onSuccessEditingDialog}>
        <InputStyle>
          <TextInput
            value={editingData.description}
            label={t("promo_flash.description")}
            onChange={onChange("description")}
            error={t(editingError.description)}
          />
        </InputStyle>
        <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
          <SelectInput
            label={"liaison"}
            error={editingError.link}
            value={editingData.link}
            onChange={onChangeSelectLink}
            data={[
              { id: 1, name: "Categories" },
              { id: 2, name: "Sous Catégories" },
              { id: 3, name: "Produit" },
            ]}
          />
          {editingData.link == "1" && (
            <SelectInputShow
              label={"Categorie"}
              error={editingError.categories}
              value={editingData.categories}
              onChange={onChangeSelectCategory}
              data={categoryData.data.map((category: any) => ({
                id: category.id,
                name: category.title,
              }))}
            />
          )}
          {editingData.link == "2" && (
            <SelectInputShow
              label={"Sous Catégories"}
              error={editingError.subCategories}
              value={editingData.subCategories ?? ""}
              onChange={onChangeSelectSubCategory}
              data={subCategoryData.data.map((subCategory: any) => ({
                id: subCategory.id,
                name: subCategory.title,
              }))}
            />
          )}
          {editingData.link == "3" && (
            <SelectInputShow
              label={"Produit"}
              error={editingError.products}
              value={editingData.products ?? ""}
              onChange={onChangeSelectProductList}
              data={productData.data.map((product: any) => ({
                id: product.id,
                name: product.title,
              }))}
            />
          )}
        </AutoInputsContainerStyle>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={editingData.value_type}
          onChange={(e) => {
            setEditingData({
              ...editingData,
              value_type: e.target.value,
            });
          }}
          style={{ display: "100%" }}
        >
          <div style={{ display: "inline-flex", width: "100%" }}>
            <FormControlLabel value={"euro"} control={<Radio  />} label="euro" />
            <FormControlLabel
              value={"percentage"}
              control={<Radio  />}
              label="pourcentage"
            />
          </div>
        </RadioGroup>
        {editingData.value_type=="euro"   && (
          <TextInput
          value={`${editingData.promotion_value}`}
          type="number"
          label={t("promo_flash.euro")}
          onChange={onChange("promotion_value")}
          error={t(editingError.promotion_value)}
        />
        )}
        {editingData.value_type=="percentage" && (
          <TextInput
            value={`${editingData.promotion_value}`}
            type="number"
            label={t("promo_flash.percentage")}
            onChange={onChange("promotion_value")}
            error={t(editingError.promotion_value)}
          />
        )}
        <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
        <InputStyle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

              <DateTimePicker
                label={t("promotion_codes.start_date_title")}
                value={
                 dayjs(editingData.start_date)

                }
                minDateTime={dayjs(new Date(new Date().setDate(new Date().getDate() - 1)))}
                //defaultValue={ dayjs(new Date())}

                onChange={(start: any) => { console.log("start",start);setEditingData({ ...editingData, start_date:start.valueOf() });setEditingError({...editingError,start_date:""}) }}
              />
<p style={{ color: 'red' }}>{editingError.start_date}</p>
            </LocalizationProvider>

          </InputStyle>


          <InputStyle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

              <DateTimePicker
                label={t("promotion_codes.end_date_title")}
                value={
                  dayjs(editingData.end_date)
                }
                minDateTime={dayjs(new Date(editingData.start_date))}
                
                onChange={(end: any) => {
                  console.log("end", end);
                  setEditingData({ ...editingData, end_date:end.valueOf() });
                  setEditingError({...editingError,end_date:""})
              
                }}
              />

<p style={{ color: 'red' }}>{editingError.end_date}</p>
            </LocalizationProvider>
          </InputStyle>
          </AutoInputsContainerStyle>
          <p><b>NB:</b> Chaque produit ne peut être associé qu'à une seule promotion flash. L'ajout d'une nouvelle promotion flash pour un produit déjà lié entraînera automatiquement la désactivation de la promotion existante.

De même, si vous ajoutez une promotion flash pour un produit qui appartient à une catégorie et qu'il existe déjà une promotion flash pour cette catégorie, la promotion existante sera automatiquement désactivée.

</p>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeEditingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default EditPromotionFlash;
