import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Puff } from "react-loader-spinner";
import {
  createPointOfSale,
  updatePointOfSale,
  getPointOfSales,
} from "UpdatedFeatures/api";
import AddPointOfSale from "./AddPosModal";
import EditPos from "./EditPos";
import { toast } from "react-toastify";
import { FaPen, FaTrash } from "react-icons/fa";
import Pagination from "UpdatedComponents/Pagination";

export default function PosTable() {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    contact_number: "",
    latitude: "",
    longitude: "",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    address: "",
    contact_number: "",
    latitude: "",
    longitude: "",
  });
  const [pos, setPos] = useState(null);
  const [selectedPos, setSelectedPos] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // Set perPage to a reasonable number

  const token = localStorage.getItem("token");
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, isError } = useQuery({
    queryKey: ["pointOfSales", page, perPage, token],
    queryFn: () => getPointOfSales(token, page, perPage),
    keepPreviousData: true, // Keep previous data while fetching new page
  });

  // Ensure totalPages is calculated correctly
  const totalPages = Math.ceil(data?.total / perPage);

  const createMutation = useMutation({
    mutationFn: ({ data, token }) => createPointOfSale(data, token),
    onSuccess: () => {
      queryClient.invalidateQueries(["pointOfSales", page, perPage, token]);
      setOpen(false);
      toast.success("Le point de vente a été ajouté !");
    },
    onError: (error) => {
      toast.error(
        `Erreur lors de l'ajout du point de vente : ${error.message}`
      );
      setOpen(false);
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, data, token }) => updatePointOfSale(id, data, token),
    onSuccess: () => {
      queryClient.invalidateQueries(["pointOfSales", page, perPage, token]);
      setOpenEdit(false);
      toast.success("Le point de vente a été modifié !");
    },
    onError: (error) => {
      toast.error(
        `Erreur lors de la modification du point de vente : ${error.message}`
      );
      setOpenEdit(false);
    },
  });

  const handleClick = (person) => {
    setOpenEdit(true);
    setPos(person);
  };

  const handleSelect = (id) => {
    setSelectedPos((prev) => {
      if (prev.includes(id)) {
        return prev.filter((selectedId) => selectedId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  if (isError) {
    return <p>Erreur : {error.message}</p>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-12">
      <EditPos
        open={openEdit}
        setOpen={setOpenEdit}
        setFormData={setEditFormData}
        formData={editFormData}
        mutation={editMutation}
        pos={pos}
      />

      <AddPointOfSale
        open={open}
        setOpen={setOpen}
        setFormData={setFormData}
        formData={formData}
        mutation={createMutation}
      />

      <div className="sm:flex sm:items-center justify-between">
        <div className="relative">
          <form className="flex items-center">
            <input
              type="text"
              placeholder="Search..."
              className="block w-full p-2 pl-10 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <button
              type="submit"
              className="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <svg
                className="w-5 h-5 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 4a7 7 0 100 14 7 7 0 000-14zm0 0l6 6"
                />
              </svg>
            </button>
          </form>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="flex items-center justify-center px-3 py-1 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
            onClick={() => setOpen(true)}
          >
            <span className="text-sm">+ Ajouter</span>
          </button>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-[#b269ae]">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6"
                    >
                      <input
                        type="checkbox"
                        className="rounded-sm"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedPos(
                              data?.data.map((person) => person.id)
                            );
                          } else {
                            setSelectedPos([]);
                          }
                        }}
                        checked={
                          selectedPos.length === data?.data.length &&
                          data?.data.length > 0
                        }
                      />
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Adresse
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Numéro de contact
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Latitude
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Longitude
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Modifier</span>
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div className="flex justify-center mx-auto">
                    <Puff
                      visible={true}
                      height="50"
                      width="50"
                      color="#895a7c"
                      ariaLabel="puff-loading"
                    />
                  </div>
                ) : (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data?.data.map((person) => (
                      <tr key={person.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <input
                            type="checkbox"
                            className="rounded-sm"
                            checked={selectedPos.includes(person.id)}
                            onChange={() => handleSelect(person.id)}
                          />
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {person.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.address}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.contact_number}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.latitude}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.longitude}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.id}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            className="text-[#b269ae] hover:text-purple-300"
                            onClick={() => handleClick(person)}
                          >
                            <FaPen />
                          </button>
                          <button className="text-[#b269ae] hover:text-purple-300 ml-4">
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={setPage}
        onNextPage={nextPage}
        onPrevPage={prevPage}
        setPage={setPage}
      />
    </div>
  );
}
